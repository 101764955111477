import { combineReducers } from "redux";
// import bugsReducer from "./bugs";
// import projectsReducer from "./projects";
import roomsReducer from "./rooms";
import customersReducer from "./customers";
import reservesReducer from "./reserves";
import usersReducer from "./users";

export default combineReducers({
  //   bugs: bugsReducer,
  customers: customersReducer,
  rooms: roomsReducer,
  reserves: reservesReducer,
  users: usersReducer,
});
