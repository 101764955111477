import { toast } from "react-toastify";

const notify = (store) => (next) => (action) => {
  switch (action.type) {
    case "rooms/roomDeleted":
      console.log("deleted");
      // toast("Room has been deleted");
      toast.success("اتاق حذف شد", {
        theme: "colored",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
      });
      break;

    case "api/callFailed":
      if (action.payload.includes("404")) {
        toast.error("آیتم یافت نشد", {
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
        });
      }
      break;
    case "reserves/reserveEdited":
      toast.success("تغییرات رزرو با موفقیت ثبت شد", {
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
      });

      break;
    default:
      break;
  }
  return next(action);
  // logger > toast > api
};

export default notify;
