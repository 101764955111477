import React, { useState, useEffect, Fragment } from "react";
import Table from "./common/table";
import Pagination from "./common/pagination";
import {
  loadCustomers,
  getCustomerById,
  deleteCustomer,
} from "../store/customers";
import _ from "lodash";
import Modal from "./common/Modal";
import AddGuest from "./AddGuest";
import { useSelector, useDispatch } from "react-redux";
import getPagedData from "../utils/arrangeTableData";
import { Button } from "devextreme-react/button";
import LoadingPanel from "./common/LoadingPanel";
import RefreshButton from "./RefreshButton";
import Swal from "sweetalert2";

// const store = configureStore();
export default function GuestList() {
  // useEffect(() => {
  //   dispatch(loadCustomers());
  // }, []);

  const customers = useSelector((state) => state.entities.customers);
  const dispatch = useDispatch();
  const currentState = useSelector((state) => state);

  //columns to create table
  const columns = [
    { title: "نام", path: "firstName", id: 1001 },
    { title: "نام خانوادگی", path: "lastName", id: 1002 },
    { title: "کد ملی/ شماره پاسپورت", path: "idNumber", id: 1003 },
    { title: "شماره تماس", path: "phone", id: 1004 },
    { title: "محل زندگی", path: "city", id: 1005 },
    { title: "شغل", path: "job", id: 1006 },
    {
      id: 1007,
      content: (customer) => (
        <div className="d-flex justify-content-around">
          <Button
            // width={120}
            className=" me-2"
            // text="Edit"
            type="success"
            icon="edit"
            hint="Click to edit"
            stylingMode="outlined"
            onClick={() => handleEdit(customer)}
          />
          <Button
            // width={120}
            className=" me-2"
            // text="Edit"
            type="danger"
            icon="trash"
            hint="Click to delete"
            stylingMode="outlined"
            onClick={() => handleDelete(customer)}
          />
        </div>
      ),
    },
  ];

  const [sortColumn, setSortColumn] = useState({
    path: "firstName",
    order: "asc",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(3);

  //toggle modal
  const [modal, showModal] = useState({
    isOpen: false,
    title: "ثبت مهمان",
  });

  //default data when open modal to add or edit item
  const [data, setData] = useState({
    id: "",
    firstName: "",
    lastName: "",
    phone: "",
    idNumber: "",
    job: "",
    birthday: "",
    reserves: [],
  });
  const pagedData = getPagedData(
    customers.list,
    searchQuery,
    columns,
    sortColumn,
    currentPage,
    pageSize
  );
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleSort = (sortColumn) => {
    setSortColumn(sortColumn);
  };

  const handleEdit = (item) => {
    setData(item);
    showModal({ isOpen: true, title: "Edit Customer" });
  };

  const handleDelete = ({ id }) => {
    Swal.fire({
      text: `Are you sure you want to delete this record? `,
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d9534f",
      cancelButtonColor: "#337ab7",
      reverseButtons: false,
      focusCancel: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteCustomer(id));
      }
    });
  };
  const resetModal = () => {
    showModal(false);
    setData({
      id: "",
      firstName: "",
      lastName: "",
      phone: "",
      idNumber: "",
      job: "",
      birthday: "",
      reserves: [],
    });
  };
  // const temp = getPagedData();
  return (
    <div className="yekan">
      <div dir="rtl">
        <Button
          // width={120}
          className=" mb-4 yekan-bold"
          text="مهمان جدید"
          type="default"
          icon="user"
          stylingMode="contained"
          onClick={() =>
            showModal({ isOpen: !modal.IsOpen, title: "ثبت مهمان" })
          }
        />
      </div>
      <div className="d-flex" dir="rtl">
        <input
          type="text"
          className="form-control mb-4"
          id="search"
          placeholder="جستجوی مهمانان"
          autoComplete="false"
          onChange={handleSearch}
        />
        <RefreshButton
          onClick={() => {
            dispatch(loadCustomers());
          }}
        />
      </div>
      <div id="GuestTable">
        <Table
          data={pagedData.data || []}
          sortColumn={sortColumn}
          classes="table-striped table-hover table-bordered align-middle table-responsive"
          columns={columns}
          onSort={handleSort}
          onDelete={() => {}}
          onClickRow={() => {}}
        ></Table>
      </div>
      <LoadingPanel
        panel="#GuestTable"
        isLoading={customers.loading}
      ></LoadingPanel>
      <Pagination
        itemsCount={pagedData.totalCount || 0}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />

      {modal.isOpen === true ? (
        <Fragment>
          <Modal
            title={modal.title}
            content={
              <AddGuest
                data={data}
                onClose={() => {
                  resetModal();
                }}
              ></AddGuest>
            }
            onClose={() => {
              resetModal();
            }}
          />
          <div className="modal-backdrop fade show"></div>
        </Fragment>
      ) : null}
    </div>
  );
}
