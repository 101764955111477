import React from "react";
import { LoadPanel } from "devextreme-react/load-panel";
import { useState } from "react";

export default function LoadingPanel(props) {
  const { isLoading, panel } = props;
  const [loadPanel, setLoadPanel] = useState({
    // loadPanelVisible: false,
    showIndicator: true,
    shading: true,
    showPane: true,
    hideOnOutsideClick: false,
  });
  return (
    <LoadPanel
      shadingColor="rgba(0,0,0,0.4)"
      position={{ of: panel }}
      onHiding={loadPanel.hideLoadPanel}
      visible={isLoading}
      showIndicator={loadPanel.showIndicator}
      shading={loadPanel.shading}
      showPane={loadPanel.showPane}
      hideOnOutsideClick={loadPanel.hideOnOutsideClick}
    />
  );
}
