import React, { Fragment } from "react";

export default function RadioButtonGroup(props) {
  const { name, onChange, selected, options } = props;

  return (
    <div
      className="btn-group d-flex justify-content-around m-auto"
      role="group"
      aria-label="Basic radio toggle button group"
    >
      {options.map(({ value, label, style, ...rest }) => (
        <Fragment key={`fragment ${label}`}>
          <label></label>
          <input
            {...rest}
            type="radio"
            className="btn-check btn-sm"
            name={name}
            key={`input ${label}`}
            id={value}
            // autocomplete="off"
            checked={selected == value ? true : false}
            onChange={() => onChange({ name, value: value })}
            // defaultChecked={option.defaultChecked ? true : false}
          />
          {/* {console.log("selected", selected)}
          {console.log(value)} */}
          <label
            className={`btn btn-outline-${style}  btn-sm `}
            key={`label ${label}`}
            htmlFor={value}
          >
            {label}
          </label>
        </Fragment>
      ))}
    </div>
  );
}
