import chroma from "chroma-js";
import React from "react";

export default function DataCell({ itemData, rooms }) {
  const { data } = itemData;
  let color = "";
  try {
    const roomId = data.groups.roomId;
    color = rooms.filter((x) => x.id === roomId)[0].color;
  } catch (error) {
    color = "#000";
  }

  // const grid={
  //     width: 100%,
  //     height: 400px,
  //     background: linear-gradient(
  //       45deg,
  //       transparent 25%,
  //       rgba(0, 0, 0, 0.01) 25%,
  //       rgba(0, 0, 0, 0.01) 50%,
  //       transparent 50%,
  //       transparent 75%,
  //       rgba(0, 0, 0, 0.01) 75%,
  //       rgba(0, 0, 0, 0.01)
  //     ),
  //     backgroundSize: "10px 10px",
  // }
  const stripeStyle = {
    background: `linear-gradient(
        45deg,
        transparent 25%,
        ${chroma(color).brighten(4)}  25%,
        ${chroma(color).brighten(4)}  50%,
        transparent 50%,
        transparent 75%,
        ${chroma(color).brighten(4)}  75%,
        ${chroma(color).brighten(4)} `,
    backgroundSize: "7px 7px",
  };

  return <div className="w-100 h-100 " style={stripeStyle}></div>;
}
