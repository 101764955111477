import React, { useState, useEffect, Fragment } from "react";
import {
  NumberBox,
  Button as NumberBoxButton,
} from "devextreme-react/number-box";
import { isToday } from "../utils/todayEvents";
import { viewReserve } from "../utils/reserveFunctions";
import { Card, Table, Container, Row, Col } from "react-bootstrap";
import { CheckBox } from "devextreme-react/check-box";
import DashboardCard from "./DashboardCard";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "devextreme-react/button";

import {
  todayEvent,
  roomStatusData,
  expectedArrivals,
  expectedDepartures,
  expectedListDepartures,
  expectedListArrivals,
  arrivalListClass,
  departureListClass,
  inHouseReserves,
  vacantRooms,
  paxCalculate,
  occupationPercent,
  listDropDown,
} from "../utils/todayEvents";
import {
  mapReserveForCalendar,
  appointmentTemplate,
  mapRoomForRack,
} from "../utils/mapReserves";
import Chart, {
  SeriesTemplate,
  CommonSeriesSettings,
  Title,
} from "devextreme-react/chart";
import PieChart, {
  Series,
  Legend,
  Label,
  Connector,
  Export,
} from "devextreme-react/pie-chart";
import Scheduler, { Resource, Scrolling } from "devextreme-react/scheduler";
import customTooltip from "./rack/tooltip";
import LoadingPanel from "./common/LoadingPanel";
import { DateBox, Button as DateBoxButton } from "devextreme-react/date-box";
import moment from "moment";
import { Popup, Position, ToolbarItem } from "devextreme-react/popup";
import mapReserve from "./../utils/mapReserves";
import DateCell, { MonthDataCell } from "../components/Scheduler/DateCell";
import ReserveCard from "./reserveWizard/reserveCard";
import Loader from "./Loader";

function DashboardNew() {
  const today = new Date();
  const day = today.toLocaleString("default", { day: "numeric" });
  const month = today.toLocaleString("default", { month: "long" });
  const year = today.toLocaleString("default", { year: "numeric" });

  const dispatch = useDispatch();

  const rawReserves = useSelector((state) => state.entities.reserves.list);
  const [isLoading, setIsLoading] = useState(false);
  const rawRooms = useSelector((state) => state.entities.rooms.list);
  const reserves = mapReserveForCalendar({ data: rawReserves });
  const roomData = mapRoomForRack({ data: rawRooms });
  const views = ["agenda", "month"];
  const [chartStart, setChartStart] = useState(new Date());
  const [chartDays, setChartDays] = useState(7);
  const [showAllArrivals, setShowAllArrivals] = useState(false);
  const [showAllDepartures, setShowAllDepartures] = useState(false);
  const [expectedArrivalsList, setExpectedArrivalsList] = useState([]);
  const [expectedDeparturesList, setExpectedDeparturesList] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupContainer, setPopupContainer] = useState(null);
  const [reserveInfo, setReserveInfo] = useState({});

  const renderDateCell = (itemData) => (
    <DateCell itemData={itemData} type="month" />
  );

  useEffect(() => {
    setExpectedArrivalsList(expectedListArrivals(rawReserves, showAllArrivals));
  }, [showAllArrivals, rawReserves]);
  useEffect(() => {
    setExpectedDeparturesList(
      expectedListDepartures(rawReserves, showAllDepartures)
    );
  }, [showAllDepartures, rawReserves]);

  return (
    <>
      {/* {isLoading && <Loader></Loader>} */}
      <Container fluid dir="rtl">
        <Row>
          <Col lg="3" sm="6">
            <DashboardCard
              title="در اقامتگاه"
              text={inHouseReserves(rawReserves).length}
              bottom="اتاق"
              className="text-info"
              footer={
                <h6 className="text-end text-muted my-auto">
                  جمع نفرات: {paxCalculate(rawRooms, rawReserves, "inHouse")}
                </h6>
              }
            ></DashboardCard>
          </Col>
          <Col lg="3" sm="6">
            <DashboardCard
              title="در انتظار رسیدن"
              text={expectedArrivals(rawReserves).length}
              bottom="اتاق"
              className="text-danger "
              footer={
                <h6 className="text-end text-muted my-auto">
                  جمع نفرات: {paxCalculate(rawRooms, rawReserves, "arrival")}
                </h6>
              }
            ></DashboardCard>
          </Col>
          <Col lg="3" sm="6">
            <DashboardCard
              title="در انتظار خروج"
              text={expectedDepartures(rawReserves).length}
              bottom="اتاق"
              className="text-warning "
              footer={
                <h6 className="text-end text-muted my-auto">
                  جمع نفرات: {paxCalculate(rawRooms, rawReserves, "departure")}
                </h6>
              }
            ></DashboardCard>
          </Col>
          <Col lg="3" sm="6">
            <DashboardCard
              title="اتاقهای خالی"
              text={roomStatusData(rawRooms, rawReserves).status[1].area}
              bottom="اتاق"
              className="text-secondary"
              footer={
                <h6 className="text-end text-muted my-auto">
                  جمع نفرات: {paxCalculate(rawRooms, rawReserves, "vacant")}
                </h6>
              }
            ></DashboardCard>
          </Col>
        </Row>
        <Row>
          <Col md="8">
            <Card className="yekan">
              <Card.Header>
                <Card.Title as="h4">رزروهای ماه</Card.Title>
                {/* <p className="card-category">24 Hours performance</p> */}
              </Card.Header>

              <Card.Body>
                {/* <div className="ct-chart" id="chartHours"> */}
                <Scheduler
                  timeZone="Asia/Tehran"
                  dataSource={reserves}
                  views={["agenda", "month"]}
                  defaultCurrentView="month"
                  defaultCurrentDate={new Date()}
                  rtlEnabled={true}
                  dataCellComponent={MonthDataCell}
                  dateCellRender={renderDateCell}
                  height={415}
                  appointmentRender={appointmentTemplate}
                  onInitialized={() => {
                    setIsLoading(true);
                  }}
                  // onContentReady={() => {
                  //   setIsLoading(false);
                  // }}
                  onAppointmentRendered={() => {
                    setIsLoading(false);
                  }}
                  onAppointmentFormOpening={(e) => {
                    e.cancel = true;
                  }}
                  startDayHour={9}
                  appointmentTooltipComponent={(e) => customTooltip(e)}
                  // appointmentRender={appointmentTemplate}
                  showCurrentTimeIndicator={false}
                >
                  <Resource
                    dataSource={roomData}
                    allowMultiple={true}
                    fieldExpr="roomId"
                    label="Room"
                    useColorAsDefault={true}
                  />
                </Scheduler>
                {/* </div> */}
              </Card.Body>
              <Card.Footer>
                {/* <div className="legend">
                  <i className="fa fa-circle text-info"></i>
                  Open <i className="fas fa-circle text-danger"></i>
                  Click <i className="fas fa-circle text-warning"></i>
                  Click Second Time
                </div> */}
                <hr></hr>
                <div className="stats">
                  <i className="fa fa-info"></i>
                  You can see month reserves here
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col md="4">
            <Card className="yekan-med">
              <Card.Header>
                <Card.Title as="h4">وضعیت اتاق ها</Card.Title>
                <p className="card-category">وضعیت اشغالی روزانه اتاق ها</p>
              </Card.Header>
              <Card.Body>
                <PieChart
                  id="pie"
                  dataSource={roomStatusData(rawRooms, rawReserves).status}
                  //   palette="Bright"
                  //   title="Rooms Status"
                  // palette="Material"
                  palette={["#F5564A", "#1DB2F5", "#97C95C"]}
                  paletteExtensionMode="blend"
                  redrawOnResize={true}
                  // export={false}
                  // onPointClick={this.pointClickHandler}
                  // onLegendClick={this.legendClickHandler}
                >
                  {/* <Size height={100} width={200} /> */}
                  <Series argumentField="status" valueField="area">
                    <Label visible={true}>
                      <Connector visible={true} width={1} />
                    </Label>
                  </Series>
                  <Legend
                    verticalAlignment="bottom"
                    horizontalAlignment="center"
                    itemTextPosition="right"
                    rowCount={1}
                  />
                  {/* <Size width={500} /> */}
                  {/* <Export enabled={true} /> */}
                </PieChart>
                {/* <div className="legend">
                  <i className="fas fa-circle text-info"></i>
                  Open <i className="fas fa-circle text-danger"></i>
                  Bounce <i className="fas fa-circle text-warning"></i>
                  Unsubscribe
                </div> */}
                <hr></hr>
                <div className="stats">
                  درصد اشغال:
                  {Math.round(
                    roomStatusData(rawRooms, rawReserves).occupationPercent
                  )}
                  <i className="fa fa-percent fa-xs"></i>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Card>
              <Card.Header>
                <div className="dx-field">
                  <div className="dx-field-label">: شروع از </div>
                  <div className="dx-field-value">
                    <DateBox
                      value={chartStart}
                      label={new Date(chartStart).toLocaleDateString("fa")}
                      stylingMode="outlined"
                      onValueChanged={(e) => setChartStart(e.value)}
                      pickerType="rollers"
                      applyValueMode="useButtons"
                    >
                      <DateBoxButton
                        name="today"
                        location="before"
                        options={{
                          text: "امروز",
                          onClick: () => {
                            setChartStart(new Date());
                          },
                        }}
                      />
                      <DateBoxButton
                        name="prevDate"
                        location="before"
                        options={{
                          icon: "spinprev",
                          stylingMode: "text",
                          onClick: () => {
                            const temp = new Date(chartStart);
                            temp.setDate(temp.getDate() - 1);
                            setChartStart(temp);
                          },
                        }}
                      />
                      <DateBoxButton
                        name="nextDate"
                        location="after"
                        options={{
                          icon: "spinnext",
                          stylingMode: "text",
                          onClick: () => {
                            const temp = new Date(chartStart);
                            temp.setDate(temp.getDate() + 1);
                            setChartStart(temp);
                          },
                        }}
                      />
                      <DateBoxButton name="dropDown" />
                    </DateBox>
                  </div>
                </div>
                <div className="dx-field">
                  <div className="dx-field-label "> : تعداد روزها </div>
                  <div className="dx-field-value">
                    <NumberBox
                      showClearButton={false}
                      showSpinButtons={true}
                      // format=
                      defaultValue={chartDays}
                      onValueChanged={(e) => setChartDays(e.value)}
                    >
                      <NumberBoxButton name="spins" />
                    </NumberBox>
                  </div>
                </div>
                {/* <Card.Title as="h4">2017 Sales</Card.Title>
                <p className="card-category">All products including Taxes</p> */}
              </Card.Header>
              <Card.Body>
                <hr></hr>
                <Chart
                  id="chart"
                  // palette="dark"
                  palette={[
                    "#9C63FF",
                    "#64C064",
                    "#EEAD51",
                    "#D2504B",
                    "#4B6BBF",
                    "#2DA7B0",
                  ]}
                  dataSource={occupationPercent(
                    rawRooms,
                    rawReserves,
                    chartStart,
                    chartDays
                  )}
                >
                  <CommonSeriesSettings
                    argumentField="date"
                    valueField="occupation"
                    type="bar"
                    ignoreEmptyPoints={true}
                  />
                  <SeriesTemplate nameField="occupation" />
                  <Title
                    text="درصد اشغال"
                    subtitle={`${chartDays}  روز از ${new Date(
                      chartStart
                    ).toLocaleDateString("fa")}`}
                  />
                  <Legend
                    verticalAlignment="bottom"
                    horizontalAlignment="center"
                    itemTextPosition="right"
                    rowCount={2}
                    visible={false}
                  />
                  <Export enabled={false} />
                </Chart>
              </Card.Body>
            </Card>
          </Col>
          {/* <Col md="6"> */}
          <Col lg="3" sm="6" id="arrivalList">
            <Card className="card-tasks yekan-med">
              <Card.Header>
                <Card.Title as="h4" className="">
                  <div className="dx-field-value text-end" dir="">
                    <div>در انتظار ورود</div>
                    <CheckBox
                      defaultValue={showAllArrivals}
                      onValueChanged={(e) => {
                        setShowAllArrivals(e.value);
                      }}
                      text="نمایش همه"
                    />
                  </div>
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="table-full-width">
                  <Table>
                    <tbody>
                      {expectedArrivalsList.map((item) => (
                        <tr key={item.id}>
                          <td
                            // onClick={setPopupVisible(true)}
                            onClick={() => {
                              setReserveInfo(mapReserve({ data: item }));
                              setPopupContainer("#arrivalList");
                              setPopupVisible(true);
                            }}
                            className={arrivalListClass(item)}
                          >
                            <span className="badge bg-dark">
                              {`${item.room.name} | ${item.reserveNumber}`}
                            </span>

                            <br></br>
                            <span style={{ cursor: "pointer" }}>
                              {`${item.customer.firstName} ${item.customer.lastName}`}
                            </span>
                          </td>
                          <td className="td-actions text-start" dir="">
                            {listDropDown(item, "arrivals", dispatch)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                {/* <div className="stats">
                  <i className="now-ui-icons loader_refresh spin"></i>
                  Updated 3 minutes ago
                </div> */}
                <div className="legend">
                  <span className="text-nowrap">
                    <i className="fas fa-circle text-info"></i>تسویه
                  </span>
                  <span className="text-nowrap">
                    <i className="fas fa-circle text-danger"></i>پرداخت نشده
                  </span>
                  <span className="text-nowrap">
                    <i className="fas fa-circle text-muted"></i>عدم حضور
                  </span>
                  <span className="text-nowrap">
                    <i className="fas fa-circle text-warning"></i>در انتظار
                    تایید
                  </span>

                  <span className="text-nowrap text-decoration-line-through">
                    <i className="fas fa-square"></i>
                    در اتاق
                  </span>
                  <span className="text-nowrap">
                    <i className="fas fa-square"></i>
                    <span className="fw-bold">تایید شده</span>
                  </span>
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6" id="departureList">
            <Card className="card-tasks  yekan-med" dir="">
              <Card.Header>
                <Card.Title as="h4">
                  <div>در انتظار خروج</div>
                  <div className="dx-field-value " dir="">
                    <CheckBox
                      defaultValue={showAllDepartures}
                      onValueChanged={(e) => {
                        setShowAllDepartures(e.value);
                      }}
                      text="نمایش همه"
                    />
                  </div>
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="table-full-width">
                  <Table>
                    <tbody>
                      {expectedDeparturesList.map((item) => (
                        <tr key={item.id}>
                          <td
                            className={departureListClass(item)}
                            onClick={() => {
                              setReserveInfo(mapReserve({ data: item }));
                              setPopupContainer("#departureList");
                              setPopupVisible(true);
                            }}
                          >
                            <span className="badge bg-dark">
                              {`${item.room.name} | ${item.reserveNumber}`}
                            </span>

                            <br></br>
                            <span style={{ cursor: "pointer" }}>
                              {`${item.customer.firstName} ${item.customer.lastName}`}
                            </span>
                          </td>
                          <td className="td-actions text-start" dir="">
                            {listDropDown(item, "departures", dispatch)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                {/* <div className="stats">
                  <i className="now-ui-icons loader_refresh spin"></i>
                  Updated 3 minutes ago
                </div> */}
                <div className="legend">
                  <span className="text-nowrap">
                    <i className="fas fa-circle text-info"></i>تسویه
                  </span>
                  <span className="text-nowrap">
                    <i className="fas fa-circle text-danger"></i>پرداخت نشده
                  </span>

                  <span className="text-nowrap text-decoration-line-through">
                    <i className="fas fa-square"></i>
                    خارج شده
                  </span>
                  <span className="text-nowrap">
                    <i className="fas fa-square"></i>
                    <span className="fw-bold">داخل اتاق</span>
                  </span>
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Popup
          visible={popupVisible}
          onHiding={() => setPopupVisible(false)}
          // on={setPopupVisible(false)}
          dragEnabled={false}
          hideOnOutsideClick={true}
          showCloseButton={true}
          showTitle={true}
          title="اطلاعات"
          container={popupContainer}
          width={300}
          height={330}

          //   position="center"
        >
          <Position at="center" my="center" of={popupContainer} />
          {reserveInfo && (
            <Fragment>
              <p className="yekan-med">
                شماره رزرو:&nbsp;
                <span>{reserveInfo.reserveNumber}</span>&nbsp;
              </p>
              <p className="yekan-med">
                نام مهمان:&nbsp;
                <span>{reserveInfo.customer}</span>&nbsp;
              </p>
              <p className="yekan-med">
                ورود:&nbsp;
                <span
                  className={isToday(reserveInfo.arrival) ? "text-success" : ""}
                >
                  {reserveInfo.arrival &&
                    new Date(reserveInfo.arrival).toLocaleDateString("fa")}
                </span>
                &nbsp;
              </p>
              <p className="yekan-med">
                خروج:&nbsp;
                <span
                  className={
                    isToday(reserveInfo.departure) ? "text-danger" : ""
                  }
                >
                  {reserveInfo.departure &&
                    new Date(reserveInfo.departure).toLocaleDateString("fa")}
                </span>
                &nbsp;
              </p>
              <p className="mt-1 yekan-med">
                مانده حساب:&nbsp;
                <span>{reserveInfo.balance}</span>&nbsp;
              </p>
              <Button
                className=""
                type="normal"
                width="100%"
                icon="fa fa-eye"
                // text="New Guest"
                stylingMode="contained"
                hint="View reserve"
                onClick={() => {
                  setPopupVisible(false);
                  viewReserve(reserveInfo);
                }}
              />
            </Fragment>
          )}
        </Popup>
      </Container>
    </>
  );
}

export default DashboardNew;
