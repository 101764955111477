import { paginate } from "./paginate";
import _, { get } from "lodash";

export default function getPagedData(
  reserves,
  searchQuery = "",
  columns,
  sortColumn,
  currentPage = 1,
  pageSize = 4
) {
  let data = reserves;

  try {
    let filtered = [];
    if (searchQuery.length > 2) {
      filtered = [];
      let newFilter = [];

      for (const property of columns) {
        if (property.path && data[0][property.path]) {
          newFilter = data.filter((c) =>
            c[property.path]
              .toString()
              .toLowerCase()
              .startsWith(searchQuery.toLowerCase())
          );
          filtered = filtered.concat(newFilter);
        }
      }
    } else {
      filtered = data;
    }

    // return [];
    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const paginated = paginate(sorted, currentPage, pageSize);
    // console.log("temp", paginated);

    return { totalCount: filtered.length, data: paginated };
  } catch (error) {}
}
