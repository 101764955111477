import React from "react";

export default function NotFound() {
  return (
    <div className="double-gradient yekan text-center">
      <img
        src="/404.jpg"
        alt="404 not found"
        style={{ width: "100%", height: "90%", objectFit: "cover" }}
      ></img>
      <div className="alert alert-info">
        <h1>صفحه مورد نظر شما یافت نشد</h1>
      </div>
    </div>
  );
}
