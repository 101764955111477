import React, { useState, useEffect, Fragment } from "react";
import Table from "./common/table";
import Pagination from "./common/pagination";
import { loadReserves, deleteReserve } from "../store/reserves";
import _ from "lodash";
import Modal from "./common/Modal";
import AddGuest from "./AddGuest";
import { useSelector, useDispatch } from "react-redux";
import getPagedData from "../utils/arrangeTableData";
import AddReserve from "./AddReserve";
import mapReserve from "../utils/mapReserves";
import { Button } from "devextreme-react/button";
import LoadingPanel from "./common/LoadingPanel";
import RefreshButton from "./RefreshButton";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import NewReserve from "./newReserve";
import getVoucher from "../utils/getVoucher";
import ReserveCard from "./reserveWizard/reserveCard";
import { viewReserve } from "../utils/reserveFunctions";

export default function ReserveList() {
  // useEffect(() => {
  //   dispatch(loadReserves());
  // }, []);

  const rawReserves = useSelector((state) => state.entities.reserves);
  const dispatch = useDispatch();
  const reserves = mapReserve({ data: rawReserves.list });
  const MySwal = withReactContent(Swal);

  // const currentState = useSelector((state) => state);

  //columns to create table
  const columns = [
    {
      id: 1010,
      title: "",
      content: (reserve) => (
        <Button
          className=""
          type="normal"
          width="100%"
          icon="fa fa-eye"
          // text="New Guest"
          stylingMode="contained"
          hint="View reserve"
          onClick={() => {
            viewReserve(reserve);
          }}
        />
      ),
    },
    { title: "وضعیت رزرو", path: "status", id: 1000 },
    {
      id: 990,
      title: "تاریخ رزرو",
      path: "createdOn",
      content: (reserve) => (
        <div>
          <p>{new Date(reserve.createdOn).toLocaleDateString("fa")}</p>
        </div>
      ),
    },
    { title: "رزرو کننده", path: "reserver", id: 10001 },
    { title: "شماره رزرو", path: "reserveNumber", id: 1001 },
    { title: "نام مهمان", path: "customer", id: 1021 },
    { title: "اتاق", path: "room", id: 1003 },

    //convert iso dates to show normal date
    {
      id: 1004,
      title: "تاریخ ورود",
      content: (reserve) => (
        <div>
          <p>{new Date(reserve.arrival).toLocaleDateString("fa")}</p>
        </div>
      ),
      path: "arrival",
    },
    {
      id: 1005,
      title: "تاریخ خروج",
      content: (reserve) => (
        <div>
          <p>{new Date(reserve.departure).toLocaleDateString("fa")}</p>
        </div>
      ),
      path: "departure",
    },

    { title: "بزرگسال", path: "adult", id: 1006 },
    { title: "مانده حساب", path: "balance", id: 1009 },
    // { title: "محل زندگی", path: "city", id: 1005 },
    // { title: "شغل", path: "job", id: 1006 },
    {
      id: 1007,
      content: (reserve) => (
        <div className="d-flex justify-content-around ">
          <Button
            className=""
            type="default"
            icon="textdocument"
            // text="New Guest"
            stylingMode="outlined"
            hint="Get Voucher"
            onClick={() => {
              getVoucher(reserve.reserveNumber, reserve.customer);
            }}
          />
          <Button
            className=""
            type="success"
            icon="edit"
            // text="New Guest"
            stylingMode="outlined"
            hint="Click to edit"
            onClick={() => {
              handleEdit(reserve);
            }}
          />
          <Button
            className=""
            type="danger"
            icon="trash"
            // text="New Guest"
            stylingMode="outlined"
            hint="Click to delete"
            onClick={() => handleDelete(reserve)}
          />
        </div>
      ),
    },
  ];
  const rawGuestForm = {
    id: "",
    firstName: "",
    lastName: "",
    phone: "",
    idNumber: "",
    job: "",
    birthday: "",
    reserves: [],
  };
  const rawReserveForm = {
    id: "",
    reserveNumber: "",
    arrival: "",
    departure: "",
    adult: 0,
    // room: undefined,
    children: 0,
    paid: 0,
    price: 0,
    discount: 0,
    balance: 0,
    description: "",
    reserver: "",
    roomId: "",
    customerId: "",
    status: "",
    color: "000",
  };

  const [sortColumn, setSortColumn] = useState({
    path: "createdOn",
    order: "asc",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);

  //toggle modal
  const [reserveModal, showReserveModal] = useState({
    isOpen: false,
    title: "New Reserve",
    content: <p></p>,
  });
  const [guestModal, showGuestModal] = useState({
    isOpen: false,
    title: "New Guest",
    content: <p></p>,
  });

  //default data when open modal to add or edit item
  const [guestData, setGuestData] = useState(rawGuestForm);
  const [reserveData, setReserveData] = useState(rawReserveForm);
  const guestContent = (
    <AddGuest
      data={guestData}
      onClose={() => {
        resetGuestModal();
      }}
    ></AddGuest>
  );
  const reserveContent = (
    <NewReserve
      data={reserveData}
      onAddGuest={() =>
        showGuestModal({
          isOpen: !guestModal.IsOpen,
          title: "ثبت مهمان",
          content: guestContent,
        })
      }
      onClose={() => {
        resetReserveModal();
      }}
    ></NewReserve>
  );
  // const reserveContent = (
  //   <AddReserve
  //     data={reserveData}
  //     onAddGuest={() =>
  //       showGuestModal({
  //         isOpen: !guestModal.IsOpen,
  //         title: "Add Guest",
  //         content: guestContent,
  //       })
  //     }
  //     onClose={() => {
  //       resetReserveModal();
  //     }}
  //   ></AddReserve>
  // );

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleSort = (sortColumn) => {
    setSortColumn(sortColumn);
  };

  const handleEdit = (item) => {
    setReserveData(item);
    showReserveModal({
      isOpen: true,
      title: "Edit Reserve",
      content: (
        <NewReserve
          data={item}
          onClose={() => {
            resetReserveModal();
          }}
        ></NewReserve>
      ),
    });
  };

  const handleDelete = ({ id }) => {
    Swal.fire({
      text: `Are you sure you want to delete this record? `,
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d9534f",
      cancelButtonColor: "#337ab7",
      reverseButtons: false,
      focusCancel: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteReserve(id));
      }
    });
    // dispatch(deleteReserve(id));
  };
  const resetReserveModal = () => {
    showReserveModal(false);
    // setGuestData(rawGuestForm);
    setReserveData(rawReserveForm);
  };
  const resetGuestModal = () => {
    showGuestModal(false);
    setGuestData(rawGuestForm);
    // setReserveData(rawReserveForm);
  };
  const pagedData = getPagedData(
    reserves,
    searchQuery,
    columns,
    sortColumn,
    currentPage,
    pageSize
  );
  //design a vertical list based on given data (status ,reserve date,reserve number,guest,room,arrival,departure,adult,child,balance)
  return (
    <div className="yekan">
      <div className="yekan-bold">
        <Button
          className="ms-2 mb-4"
          type="success"
          icon="event"
          text="رزرو جدید"
          stylingMode="contained"
          hint="Add new reserve"
          onClick={() =>
            showReserveModal({
              isOpen: !reserveModal.IsOpen,
              title: "افزودن رزرو",
              content: reserveContent,
            })
          }
        />
        <Button
          className="ms-2 mb-4"
          type="default"
          icon="fa fa-user-plus"
          // icon="user"
          text="مهمان جدید"
          stylingMode="contained"
          hint="Add new guest"
          onClick={() =>
            showGuestModal({
              isOpen: !guestModal.IsOpen,
              title: "ثبت مهمان",
              content: guestContent,
            })
          }
        />
      </div>
      <div className="d-flex">
        <input
          type="text"
          className="form-control mb-4"
          id="search"
          placeholder="جستجوی رزرو ها"
          autoComplete="false"
          onChange={handleSearch}
        />
        <RefreshButton
          onClick={() => {
            dispatch(loadReserves());
          }}
        />
      </div>
      <div id="reserveList">
        <Table
          data={pagedData ? pagedData.data : []}
          sortColumn={sortColumn}
          classes="table-striped table-hover table-bordered align-middle table-responsive"
          columns={columns}
          onSort={handleSort}
          onDelete={() => {}}
          onClickRow={(x) => {}}
        ></Table>
      </div>

      <LoadingPanel
        panel="#reserveList"
        isLoading={rawReserves.loading}
      ></LoadingPanel>
      <Pagination
        itemsCount={pagedData ? pagedData.totalCount : 0}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />

      {reserveModal.isOpen === true ? (
        <Fragment>
          <Modal
            title={reserveModal.title}
            content={reserveModal.content}
            onClose={() => {
              resetReserveModal();
            }}
          />
          <div className="modal-backdrop fade show"></div>
        </Fragment>
      ) : null}
      {guestModal.isOpen === true ? (
        <Fragment>
          <Modal
            //class to show user dialog on reserve dialog
            myClass="second-modal-dialog "
            title={guestModal.title}
            content={guestModal.content}
            onClose={() => {
              resetGuestModal();
            }}
          />
          <div className="modal-backdrop second-modal-back fade show"></div>
        </Fragment>
      ) : null}
    </div>
  );

  // function viewReserve(reserve) {
  //   MySwal.fire({
  //     title: <h1 className="yekan">اطلاعات رزرو</h1>,
  //     // icon: "info",
  //     html: <ReserveCard reserve={reserve}></ReserveCard>,
  //     showCloseButton: true,
  //     showCancelButton: true,
  //     focusConfirm: false,
  //     confirmButtonText: '<i class="fa fa-print"></i> دریافت تاییدیه',
  //     confirmButtonAriaLabel: "Thumbs up, great!",
  //     cancelButtonText: '<i class="fa fa-ban"></i> بستن',
  //     cancelButtonAriaLabel: "Thumbs down",
  //   }).then((result) => {
  //     /* Read more about isConfirmed, isDenied below */
  //     if (result.isConfirmed) {
  //       getVoucher(reserve.reserveNumber, reserve.customer);
  //     }
  //   });
  // }
}
