export function makeList(data = [], labels = [], value = "", color = "#000") {
  const options = [];

  data.forEach((item) => {
    let label = "";
    for (let index = 0; index < labels.length; index++) {
      label += item[labels[index]] + " ";
    }
    if (label != "undefined " && !options.some((x) => x.label === label)) {
      options.push({
        value: item[value],
        label,
        color: item["color"] || color,
      });
    }
  });
  return options;
}
