import http from "./httpService";
import { apiUrl } from "../config.js";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import jwt_Decode from "jwt-decode";
import moment from "moment";
import { get } from "lodash";

const apiEndpoint = apiUrl + "/";

export async function changePass(data, fromAdmin = false) {
  console.log("Sss");
  const { newPassword, password_confirmation, userName, oldPassword } = data;
  if (newPassword !== password_confirmation) {
    toast.error("رمز ورودی یکسان نیست");
    return;
  }
  delete data.password_confirmation;
  if (fromAdmin) {
    delete data.oldPassword;
  }

  try {
    await http.request({
      baseURL: apiEndpoint,
      url: fromAdmin
        ? "/authenticate/changepasswordbyadmin"
        : "/authenticate/changepassword",
      method: "post",
      data: data,
    });
  } catch {
    toast.error("تغییر رمز انجام نشد");
    return;
  }
  toast.success("تغییر رمز با موفقیت انجام شد");
}
export async function register(user, isAdmin = false) {
  await http.request({
    baseURL: apiEndpoint,
    url: isAdmin ? "/authenticate/register-admin" : "/authenticate/register",
    method: "post",
    data: user,
  });
  toast.success("User created successfully");
}
export async function login(user) {
  const { data: jwt } = await http.request({
    baseURL: apiEndpoint,
    url: "/authenticate/login",
    method: "post",
    data: user,
  });

  localStorage.setItem("token", jwt.token);
  localStorage.setItem("timeout", jwt.expiration);
  toast.success("با موفقیت وارد شدید");
  http.setJwt(jwt.token);
}

export async function logout() {
  localStorage.removeItem("token");
  toast.warning("Logged out successfully");
  http.setJwt("");
}
export async function autoLogout() {
  const expireOn = localStorage.getItem("timeout");
  if (expireOn && new Date(expireOn) < new Date()) {
    localStorage.removeItem("token");
    localStorage.removeItem("timeout");
    http.setJwt("");
    toast.warning("Session timeout! please login again");
  }
}

export function getJwt() {
  return localStorage.getItem("token");
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem("token");
    const user = jwt_Decode(jwt);
    const userArray = Object.keys(user).map((key) => user[key]);
    // console.log(userArray);
    return userArray[0];
  } catch (ex) {
    return null;
  }
}
export function isUserAdmin() {
  try {
    const jwt = localStorage.getItem("token");
    const user = jwt_Decode(jwt);
    const userArray = Object.keys(user).map((key) => user[key]);
    // console.log(userArray);
    return userArray[2].some((x) => x === "Admin");
  } catch (ex) {
    return null;
  }
}

export default {
  register,
  login,
  logout,
  getCurrentUser,
};
