import React from "react";
import CardBody from "./cardBody";
import CardFooter from "./cardFooter";
import CardImage from "./cardImage";
import Card from "./card";
// import hr from "../../assets/img/HR.jpg";

function CardGrid(props) {
  return (
    <div className="row row-cols-1 row-cols-md-4 g-4">
      {props.data.map((r) => (
        <Card key={r.id} item={r} {...props} button={props.cardButton} />
      ))}
      {/* <Card /> */}
    </div>
  );
}

export default CardGrid;
