import React, { Component } from "react";
import TableBody from "./tableBody";
import TableHeader from "./tableHeader";

export default function Table(props) {
  const {
    data,
    columns,
    sortColumn,
    onSort,
    onLike,
    onDelete,
    onClickRow,
    classes = "",
  } = props;
  return (
    <table className={`table ${classes}`} dir="rtl">
      <TableHeader
        columns={columns}
        sortColumn={sortColumn}
        onSort={onSort}
      ></TableHeader>
      <TableBody
        columns={columns}
        data={data}
        onLike={onLike}
        onDelete={onDelete}
        onClickRow={onClickRow}
      ></TableBody>
    </table>
  );
}
