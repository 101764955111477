// import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { editRoom, getRoomById } from "../store/rooms";
import { editReserve } from "./../store/reserves";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

// for better implement we need to have folio and payments in order to calculate everything. but for instance just make it balance
const balance = (reserve, dispatch) => {
  const edited = { ...reserve };
  edited.balance = 0;
  dispatch(editReserve(edited));
};
const checkIn = (reserve, dispatch) => {
  const editedReserve = { ...reserve };
  const editedRoom = { ...reserve.room };
  editedReserve.status = "inhouse";
  editedRoom.status = "occupied";
  dispatch(editReserve(editedReserve));
  dispatch(editRoom(editedRoom));
};
const checkOut = (reserve, dispatch) => {
  const editedReserve = { ...reserve };
  const editedRoom = { ...reserve.room };
  editedReserve.status = "checkout";
  editedRoom.status = "dirty";
  dispatch(editReserve(editedReserve));
  dispatch(editRoom(editedRoom));
  //   toast.success("Check-In Done!", {
  //     theme: "colored",
  //   });
};

const setReserve = (reserve, dispatch, status) => {
  const editedReserve = { ...reserve };
  const editedRoom = { ...reserve.room };
  switch (status) {
    case "balance":
      editedReserve.paid =
        reserve.paid + reserve.price - (reserve.paid + reserve.discount);
      editedReserve.balance = 0;
      break;
    case "checkIn":
      editedReserve.status = "inhouse";
      editedRoom.status = "occupied";
      dispatch(editRoom(editedRoom));
      break;
    case "checkOut":
      if (reserve.balance !== 0) {
        toast.warning(
          "بدون تسویه حساب امکان ثبت خروج وجود ندارد",

          {
            theme: "colored",
          }
        );
        return;
      }
      editedReserve.status = "checkout";
      editedRoom.status = "dirty";
      dispatch(editRoom(editedRoom));
      break;
    case "cancel":
      editedReserve.status = "cancel";
      break;
    case "noShow":
      editedReserve.status = "noshow";
      break;
    case "changeRoom":
      Swal.fire({
        //   title: "Do you want to save changes",
        text: `امکان تغییر اتاق از بخش وضعیت هتل امکان پذیر است`,
        //   footer: `Reserve No.:${e.appointmentdData.text} | Guest:${e.appointmentData.customer} `,
        icon: "info",
        // showCancelButton: true,
        confirmButtonColor: "#337ab7",
        // cancelButtonColor: "#d9534f",
        reverseButtons: false,
        focusConfirm: true,
        // cancelButtonText: "No",
        confirmButtonText: "Ok",
      });
      break;
    default:
      break;
  }
  dispatch(editReserve(editedReserve));
};

const reserveActions = { balance, setReserve };
export default reserveActions;
