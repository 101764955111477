import React, { Component } from "react";
import InfoButton from "./infoButton";

class Textarea extends Component {
  render() {
    const {
      name,
      label,
      errors,
      req,
      tooltip,
      onChange,
      type = "text",
      ...rest
    } = this.props;
    let inputClass = this.setClass(errors);
    return (
      <div className="form-group mb-3">
        <label htmlFor={{ name }}>
          {label}{" "}
          {req && (
            <i
              style={{ fontSize: ".7rem" }}
              className="text-danger fa fa-asterisk"
            ></i>
          )}
        </label>

        <textarea
          {...rest}
          onChange={(e) => onChange(e.target)}
          name={name}
          // value={value}
          rows="3"
          id={name}
          className={this.setClass(errors)}
          placeholder={label}
        />

        {errors && <div className="text-danger ">{errors}</div>}
      </div>
    );
  }

  setClass(errors) {
    let inputClass = "form-control ";
    inputClass += errors ? "border border-danger" : "";
    return inputClass;
  }
}

export default Textarea;
