import React from "react";
import "../assets/css/loader.css";
export default function Loader() {
  return (
    <div className="loader">
      <div className="spinner">
        {/* <p>loading...</p> */}
        <img src={require("../assets/img/Dmaleklogo.png")} alt="Loading..." />
      </div>
    </div>
  );
}
