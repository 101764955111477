import React, { useState } from "react";
// import { DatePicker } from "zaman";
import "../../assets/css/datepicker.css";
import Scheduler, { Resource } from "devextreme-react/scheduler";
const currentDate = new Date(2021, 5, 2, 11, 30);
const groups = ["employeeID"];
const views = ["month"];

export default function DevexDashboard() {
  const [view, setView] = useState("cal");
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  return <></>;
}
