import React, { Component } from "react";
import InfoButton from "./infoButton";
import persianRex from "persian-rex";

//select all text on focus
const handleFocus = (event) => event.target.select();

class Input extends Component {
  render() {
    const {
      name,
      label,
      errors,
      req,
      tooltip,
      onChange,
      type = "text",
      ...rest
    } = this.props;
    let inputClass = this.setClass(errors);
    function isRtl() {
      return persianRex.text.test(label);
    }
    return (
      <div className="form-group mb-3 " {...rest} dir={isRtl() ? "rtl" : "ltr"}>
        {/* {(type === "date" || type === "number") && (
            <span className="input-group-text" id={name}>
              {label}
            </span>
          )} */}
        <label htmlFor={name}>
          {label}
          {req && (
            <i
              style={{ fontSize: ".7rem" }}
              className="text-danger fa fa-asterisk"
            ></i>
          )}
        </label>
        <input
          {...rest}
          onChange={(e) => onChange(e.target)}
          name={name}
          // value={value}
          type={type}
          autoComplete="off"
          id={name}
          className={this.setClass(errors)}
          placeholder={label}
          onFocus={handleFocus}
        />
        {tooltip && <InfoButton tooltip={tooltip || null}></InfoButton>}
        {errors && <div className="text-danger ">{errors}</div>}
      </div>
    );
  }

  setClass(errors) {
    let inputClass = "form-control ";
    inputClass += errors ? "border border-danger" : "";
    return inputClass;
  }
}

export default Input;
