import React, { useEffect, useState } from "react";
import { isUserAdmin } from "../services/authService";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "devextreme-react/button";
import Register from "./Auth/Register";
import Modal from "./common/Modal";
import { loadUsers } from "./../store/users";
import UserCard from "./UseCard/UserCard";
import RefreshButton from "./RefreshButton";
import ChangePassword from "./Auth/ChangePassword";

export default function UserManagement() {
  const dispatch = useDispatch();
  const [registerModal, showRegisterModal] = useState({
    isOpen: false,
    title: "Register",
  });
  const [changePassModal, showChangePassModal] = useState({
    isOpen: false,
    title: "Change password",
    userName: "",
  });
  useEffect(() => {
    dispatch(loadUsers());
  }, []);
  const users = useSelector((state) => state.entities.users.list);

  const resetModal = () => {
    showRegisterModal({
      isOpen: false,
      title: "Register",
    });
    showChangePassModal({
      isOpen: false,
      title: "Change password",
      userName: "",
    });
  };

  //   function handleChangePass() {
  //     showChangePassModal({ isOpen: true, title: "تغییر  رمز" });
  //   }
  return (
    <>
      {isUserAdmin() ? (
        <div>
          <div className="yekan">
            <div className="yekan-bold">
              <Button
                className=" mb-2"
                text="افزودن کاربر"
                type="default"
                icon="fa fa-user-plus"
                stylingMode="contained"
                onClick={() =>
                  // showModal({ isOpen: !modal.IsOpen, title: "افزودن کاربر" })
                  showRegisterModal({
                    isOpen: true,
                    title: "افزودن کاربر جدید",
                  })
                }
              />
            </div>
            <hr></hr>
            <div className="d-flex">
              <RefreshButton
                onClick={() => {
                  dispatch(loadUsers());
                }}
              />
            </div>
          </div>
          <div className=" text-center d-flex">
            {users.map((x) => (
              <div key={x.userName} className="col-md-3 p-2">
                <UserCard
                  user={x}
                  onChangePassword={(x) =>
                    showChangePassModal({
                      isOpen: true,
                      title: "تغییر رمز",
                      userName: x,
                    })
                  }
                ></UserCard>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <h1 className="alert-info p-4 yekan-bold ">
          <i className="fa fa-ban mx-4 text-danger"></i>
          شما به این بخش دسترسی ندارید.
        </h1>
      )}

      {registerModal.isOpen === true ? (
        <>
          <Modal
            title={registerModal.title}
            content={
              <Register
                onClose={() => {
                  resetModal();
                }}
              ></Register>
            }
            onClose={() => {
              resetModal();
            }}
          />
          <div className="modal-backdrop fade show"></div>
        </>
      ) : null}
      {changePassModal.isOpen === true ? (
        <>
          <Modal
            title={changePassModal.title}
            content={
              <ChangePassword
                onClose={() => {
                  resetModal();
                }}
                userName={changePassModal.userName}
                fromAdmin={true}
              ></ChangePassword>
            }
            onClose={() => {
              resetModal();
            }}
          />
          <div className="modal-backdrop fade show"></div>
        </>
      ) : null}
    </>
  );
}
