import React, { Component } from "react";
// import { persianRex } from "persian-rex";
import persianRex from "persian-rex";

export default class CardPart extends Component {
  render() {
    const {
      title,
      number,
      color,
      text,
      button,
      small,
      item,
      classes = "",
    } = this.props;

    return (
      <div className={classes}>
        {title && (
          <div className="card-title d-flex justify-content-between">
            {title.map((title) => (
              <h3 key={title}>{title}</h3>
            ))}
          </div>
        )}
        {text && (
          <div className="card-text d-flex justify-content-between">
            {text.map((text) => (
              <p key={text}>{text}</p>
            ))}
          </div>
        )}
        {small && <small className="card-text">{small}</small>}
        {color && classes.includes("card-body") && (
          <input
            readOnly
            style={{ backgroundColor: color, width: "50px" }}
            className="btn btn-sm  ms-2 "
          ></input>
        )}
        {number && <small className="card-text">{number}</small>}
        {button && button.map((b) => b(item))}
      </div>
    );
  }
}
