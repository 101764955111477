import React, { useState } from "react";
import Input from "./common/input";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import _ from "lodash";
import { addCustomer, editCustomer } from "../store/customers";
import { useDispatch } from "react-redux";

export default function AddGuest(props) {
  const dispatch = useDispatch();

  const [data, setData] = useState(props.data);
  const schema = {
    id: Joi.string().allow("").optional(),
    isDeleted: Joi.optional(),
    createdOn: Joi.optional(),
    firstName: Joi.string()
      .required()
      .max(30)
      .label("نام")
      .error(() => {
        return {
          message: "نام را وارد کنید",
        };
      }),
    lastName: Joi.string()
      .max(20)
      .required()
      .label("نام خانوادگی")
      .error(() => {
        return {
          message: "نام خانوادگی را به طور صحیح وارد کنید",
        };
      }),
    idNumber: Joi.string()
      .max(10)
      .required()
      .label("کد ملی")
      .error(() => {
        return {
          message: "کد ملی یا شماره شناسنامه را به طور صحیح وارد کنید",
        };
      }),
    reserves: Joi.array().items(Joi.string().allow("").optional()),
    // home: Joi.string().allow("").optional().label("محل زندگی"),
    job: Joi.string().allow("").optional().label("شغل"),
    birthday: Joi.string().allow("").optional().label("تاریخ تولد"),
    phone: Joi.string()
      .max(14)
      .min(6)
      .required()
      .label("شماره تماس")
      .error(() => {
        return {
          message: "شماره تماس را به طور صحیح وارد کنید",
        };
      }),
  };

  const [errors, setErrors] = useState({});
  //   const [formIsValid, setFormValidation] = useState(false);

  const validate = () => {
    const result = Joi.validate(data, schema, {
      abortEarly: false,
    });

    if (!result.error) return null;
    let errors = {};
    toast.error("error");
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    setErrors(errors);
    return errors;
  };
  //   const formErrors = validate();

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const inputSchema = { [name]: schema[name] };
    const { error } = Joi.validate(obj, inputSchema);
    if (!error) return null;
    return error.details[0].message;
  };

  const handleChange = (input) => {
    const inputData = { ...data };
    const inputErrors = { ...errors };
    const errorMessage = validateProperty(input);
    if (errorMessage) inputErrors[input.name] = errorMessage;
    else delete inputErrors[input.name];
    inputData[input.name] = input.value;
    setData(inputData);
    setErrors(inputErrors);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors || {});

    if (errors) {
      return;
    }
    if (data === props.data) props.onClose();

    data.id ? dispatch(editCustomer(data)) : dispatch(addCustomer(data));
    props.onClose();
    // store.dispatch(addCustomer(data));

    // this.doSubmit();
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="card-body yekan-med">
        <Input
          name="firstName"
          label="نام"
          onChange={handleChange}
          value={data["firstName"]}
          errors={errors["firstName"]}
          req={true}
        ></Input>

        <Input
          name="lastName"
          label="نام خانوادگی"
          onChange={handleChange}
          value={data["lastName"]}
          errors={errors["lastName"]}
          req={true}
        ></Input>
        <Input
          name="idNumber"
          label="شماره ملی/ پاسپورت"
          onChange={handleChange}
          value={data["idNumber"]}
          errors={errors["idNumber"]}
          req={true}
        ></Input>
        <Input
          name="phone"
          label="شماره تماس"
          onChange={handleChange}
          value={data["phone"]}
          errors={errors["phone"]}
          req={true}
        ></Input>
        <Input
          name="job"
          label="شغل"
          onChange={handleChange}
          value={data["job"]}
          errors={errors["job"]}
        ></Input>
        <Input
          name="birthday"
          label="تاریخ تولد"
          type="date"
          onChange={handleChange}
          value={data["birthday"]}
          errors={errors["birthday"]}
        ></Input>
      </div>
      <div className="d-grid gap-2 bd-highlight yekan-bold">
        <button type="submit" className="btn btn-primary">
          ذخیره
        </button>
        <button
          type="button"
          className="btn btn-light "
          onClick={() => props.onClose()}
          // disabled={Boolean(validate())}
        >
          انصراف
        </button>
      </div>
    </form>
  );
}
