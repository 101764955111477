import moment from "moment";
import _ from "lodash";

export function checkTodayReserves(reserves) {
  const today = moment();
  today.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  // console.log(today);
  const activeReserves = reserves.filter(
    (x) =>
      moment(x.arrival)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .isSameOrBefore(today) &&
      moment(x.departure)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .isSameOrAfter(today)
  );

  return activeReserves;
}

export function checkByDate(reserves, arrival, departure) {
  var dateOne = new Date(arrival);
  var dateTwo = new Date(dateOne);
  dateTwo.setDate(dateOne.getDate() + 1);

  // console.log(reserves)

  // let dateOne = new Date(startDate);
  // let dateTwo = new Date(departure);
  // let dateTwo = startDate.getDate() + 1;

  let filteredReserves = [];
  let count = 0;

  // console.log(room);
  for (let date = dateOne; date < dateTwo; date.setDate(date.getDate() + 1)) {
    filteredReserves = _.union(
      filteredReserves,
      reserves.filter(
        (x) =>
          moment(x.arrival)
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .isSameOrBefore(
              moment(date).set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              })
            ) &&
          // x.room === room &&
          moment(x.departure)
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .isSameOrAfter(
              moment(date).set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              })
            )
      )
    );
  }

  return filteredReserves;
}

// export function checkToday(reserves, rooms) {}
