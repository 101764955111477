import React from "react";
import chroma from "chroma-js";

export default function ResourceCell({ itemData }) {
  const { data } = itemData;
  const color = data.color;
  const stripeStyle = {
    background: `repeating-linear-gradient(45deg, ${chroma(color).brighten(
      5
    )}, ${chroma(color).brighten(5)} 10px, ${chroma("#000").brighten(
      5.4
    )} 10px, ${chroma("#000").brighten(5.4)} 20px)`,
  };

  return (
    <div
      className=" align-items-center d-flex justify-content-center w-100 h-100 yekan-med"
      //   style={{ backgroundColor: chroma(data.color).brighten(1) }}
      style={stripeStyle}
    >
      <i className="fa fa-bed fs-1 " style={{ color: "rgba(0,0,0,0.1)" }}></i>
      <h5 className="position-absolute " style={{ color: data.color }}>
        <i className="fa fa-circle fa-sm"></i>
        {data.text}
      </h5>
    </div>
  );
}
