import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReserveCard from "./../components/reserveWizard/reserveCard";
import getVoucher from "./getVoucher";

export function checkAvailability(reserves, arrival, departure) {
  arrival = new Date(arrival).getTime();
  departure = new Date(departure).getTime();
  let result = true;
  reserves.forEach((reserve) => {
    const currentCheckIn = new Date(reserve.arrival).getTime();
    const currentCheckOut = new Date(reserve.departure).getTime();
    if (
      (currentCheckIn < arrival && currentCheckOut <= arrival) ||
      (currentCheckIn >= departure && currentCheckOut > departure)
    ) {
    } else {
      result = false;
      // return result;
    }
  });

  return result;
}

export function getAvailableRooms(reserves, allRooms, arrival, departure) {
  const departureDate = new Date(departure).toISOString().split("T")[0];
  const arrivalDate = new Date(arrival).toISOString().split("T")[0];

  const relativeReserves = reserves.filter(
    (x) =>
      new Date(x.arrival).toISOString().split("T")[0] <= departureDate &&
      new Date(x.departure).toISOString().split("T")[0] > arrivalDate
  );

  const relativeRooms = [];
  relativeReserves.forEach((reserve) => {
    relativeRooms.push(reserve.room.id);
  });
  const availableRooms = allRooms.filter(
    (room) => !relativeRooms.includes(room.id)
  );

  return availableRooms;
}

export function viewReserve(reserve) {
  const MySwal = withReactContent(Swal);

  MySwal.fire({
    title: <h1 className="yekan">اطلاعات رزرو</h1>,
    // icon: "info",
    html: <ReserveCard reserve={reserve}></ReserveCard>,
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: '<i class="fa fa-print"></i> دریافت تاییدیه',
    confirmButtonAriaLabel: "Thumbs up, great!",
    cancelButtonText: '<i class="fa fa-ban"></i> انصراف',
    cancelButtonAriaLabel: "Thumbs down",
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      getVoucher(reserve.reserveNumber, reserve.customer);
    }
  });
}
