import React, { useState, useEffect, Fragment } from "react";
import Table from "./common/table";
import Pagination from "./common/pagination";
import { loadRooms, getRoomById, deleteRoom } from "../store/rooms";
import configureStore from "../store/configureStore";
import _ from "lodash";
import Modal from "./common/Modal";
import AddRoom from "./AddRoom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import getPagedData from "../utils/arrangeTableData";
import CardGrid from "./common/card/cardGrid";
import { Button } from "devextreme-react/button";
import { LoadPanel } from "devextreme-react/load-panel";
import LoadingPanel from "./common/LoadingPanel";
import RefreshButton from "./RefreshButton";

// const store = configureStore();
export default function RoomList() {
  // useEffect(() => {
  //   dispatch(loadRooms());
  // }, []);

  const dispatch = useDispatch();
  // const currentState = useSelector((state) => state);

  // buttons to show in footer
  const cardButton = [
    (room) => (
      <Button
        // width={120}
        className=" me-2"
        text=""
        key={`edit ${room.id}`}
        type="success"
        hint="Click to edit"
        icon="edit"
        stylingMode="outlined"
        onClick={() => handleEdit(room)}
      />
    ),
    (room) => (
      <Button
        // width={120}
        className=" me-2"
        // text="Delete"
        key={`delete ${room.id}`}
        type="danger"
        hint="Click to delete"
        icon="trash"
        stylingMode="outlined"
        onClick={() => handleDelete(room)}
      />
    ),
  ];

  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(3);
  const isLoading = useSelector((state) => state.entities.rooms.loading);
  const rooms = useSelector((state) => state.entities.rooms.list).filter(
    (c) =>
      c.name.toLowerCase().startsWith(searchQuery.toLowerCase()) ||
      c.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  //toggle modal
  const [modal, showModal] = useState({
    isOpen: false,
    title: "Add Room",
  });

  //default data when open modal to add or edit item
  const [data, setData] = useState({
    id: "",
    name: "",
    enName: "",
    color: "",
    number: "",
    capacity: "",
    description: "",
    enDescription: "",
  });

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };
  // const handlePageChange = (page) => {
  //   setCurrentPage(page);
  // };

  const handleEdit = (item) => {
    // const customer = getRoomById(currentState, item.id);

    setData({
      id: item.id,
      name: item.name,
      enName: item.enName,
      color: item.color,
      number: item.number,
      capacity: item.capacity,
      description: item.description,
      enDescription: item.enDescription,
    });
    showModal({ isOpen: true, title: "ویرایش اتاق" });
  };

  const handleDelete = (room) => {
    Swal.fire({
      text: `Are you sure want to delete ${room.name}   ${room.enName}   ?`,
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      reverseButtons: true,
      focusCancel: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteRoom(room.id));
      }
    });
  };
  const resetModal = () => {
    showModal(false);
    setData({
      id: "",
      name: "",
      enName: "",
      color: "",
      number: "",
      capacity: "",
      enDescription: "",
      description: "",
    });
  };
  // const temp = getPagedData();
  return (
    <div className="yekan">
      <div className="yekan-bold">
        <Button
          className=" mb-2"
          text="افزودن اتاق"
          type="default"
          icon="fa fa-bed"
          stylingMode="contained"
          onClick={() =>
            showModal({ isOpen: !modal.IsOpen, title: "افزودن اتاق" })
          }
        />
      </div>
      <div className="d-flex">
        <input
          type="text"
          className="form-control mb-4"
          id="search"
          placeholder="جستجوی اتاق"
          autoComplete="false"
          onChange={handleSearch}
        />
        <RefreshButton
          onClick={() => {
            dispatch(loadRooms());
          }}
        />
      </div>

      <CardGrid id="list" data={rooms} cardButton={cardButton}></CardGrid>

      <LoadingPanel panel="#list" isLoading={isLoading}></LoadingPanel>
      <hr />
      {modal.isOpen === true ? (
        <Fragment>
          <Modal
            title={modal.title}
            content={
              <AddRoom
                data={data}
                onClose={() => {
                  resetModal();
                }}
              ></AddRoom>
            }
            onClose={() => {
              resetModal();
            }}
          />
          <div className="modal-backdrop fade show"></div>
        </Fragment>
      ) : null}
    </div>
  );
}
