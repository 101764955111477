import React from "react";
import { currency, language } from "../config.js";
import "../dashboardList.css";
import { checkByDate } from "./checkRoomReserve";
import moment from "moment";
import _ from "lodash";
import { ContextMenu } from "devextreme-react/context-menu";
import DropDownButton from "devextreme-react/drop-down-button";
import reserveActions from "./reservationActions.js";
import { useSelector, useDispatch } from "react-redux";
import momentJalaali from "moment-jalaali";

momentJalaali.loadPersian({
  dialect: "persian-modern",
  usePersianDigits: true,
});

export function roomStatusData(rooms, reserves) {
  const activeRooms = checkByDate(reserves, new Date()).filter(
    (x) => !isToday(x.departure)
  );
  const ooo = rooms.filter((x) => x.status === "ooo");
  const occupationPercent = (activeRooms.length / rooms.length) * 100;
  const occupied = activeRooms.length;
  const vacant = rooms.length - activeRooms.length - ooo.length;
  return {
    status: [
      { status: "اشغال شده", area: occupied },
      { status: "خالی", area: vacant },
      { status: "خارج از سرویس", area: ooo.length },
    ],
    occupationPercent,
  };
}

// date.setDate(date.getDate() + 1)

export function occupationPercent(
  rooms,
  reserves,
  from = new Date(),
  days = 7
  // to = new Date().getDate() + 7
) {
  let to = new Date(from);
  const occupationPercents = [];
  for (let i = 0; i <= days; i++) {
    to.setDate(from.getDate() + i);
    const activeRooms = checkByDate(reserves, to)
      ? checkByDate(reserves, to).filter((x) => !isToday(x.departure))
      : [];
    const occupation = (activeRooms.length / rooms.length) * 100;
    occupationPercents.push({
      i,
      // date: moment(to).format("dd-DD-MMM"),
      date: momentJalaali(to).format("jYYYY/jM/jD"),

      occupation,
    });
  }
  return occupationPercents;
}

export const isToday = (date) => {
  return moment(date)
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .isSame(
      moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
    );
};

const currencyFormatter = new Intl.NumberFormat(language, {
  style: "currency",
  currency: currency,
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const onSetReserveStatus = (e, item, dispatch) => {
  reserveActions.setReserve(item, dispatch, e.itemData.value);
  // alert(e.itemData.name || e.itemData, "success", 600);
};

export function todayEvent(item, type, dispatch) {
  return (
    <div className="reserve ">
      {/* <img src={item.ImageSrc} /> */}
      <div className="">Reserve No : {item.reserveNumber}</div>

      <div className="dx-field d-flex">
        {/* <div className="dx-field-label">Custom main button action</div> */}
        <div className="dx-field-value  ">
          <DropDownButton
            // splitButton={true}
            useSelectMode={false}
            text={item.customer.firstName + " " + item.customer.lastName}
            icon="user"
            items={
              type === "arrivals"
                ? arrivalListMenuItems
                : departureListMenuItems
            }
            displayExpr="name"
            keyExpr="id"
            // onButtonClick={onButtonClick}
            onItemClick={(e) => onSetReserveStatus(e, item, dispatch)}
          />
        </div>
      </div>

      <div></div>
      {/* <div>{item.balance}</div> */}
      <div
        className={`price ${
          item.balance === 0 ? "text-success" : "text-danger"
        }`}
      >
        {currencyFormatter.format(item.balance)}
      </div>
    </div>
  );
}

export function listDropDown(item, type, dispatch) {
  return (
    <DropDownButton
      // splitButton={true}
      useSelectMode={false}
      // text={item.customer.firstName + " " + item.customer.lastName}

      dropDownOptions={{ width: "10rem" }}
      icon="fa fa-bars"
      items={
        type === "arrivals" ? arrivalListMenuItems : departureListMenuItems
      }
      displayExpr="name"
      keyExpr="id"
      // onButtonClick={onButtonClick}
      onItemClick={(e) => onSetReserveStatus(e, item, dispatch)}
    />
  );
}
export function menuItemClick(e, item) {
  const { itemData, itemElement, itemIndex } = e;
  if (!e.itemData.items) {
    console.log(itemData);
    console.log(item);

    // console.log(expectedArrivals(reserves));
    // notify(`The "${e.itemData.text}" item was clicked`, "success", 1500);
  }
}

export function expectedArrivals(reserves) {
  return reserves.filter(
    (x) => moment(x.arrival).isSame(moment(), "day") && x.status === "confirmed"
  );
}

export function expectedDepartures(reserves) {
  return reserves.filter(
    (x) => moment(x.departure).isSame(moment(), "day") && x.status === "inhouse"
  );
}

export function expectedListArrivals(reserves, showAll) {
  let arrivals = reserves.filter((x) =>
    moment(x.arrival).isSame(moment(), "day")
  );
  if (!showAll) arrivals = arrivals.filter((x) => x.status !== "inhouse");
  return arrivals;
}

export function expectedListDepartures(reserves, showAll) {
  let departures = reserves.filter(
    (x) => moment(x.departure).isSame(moment(), "day") && x.status !== "noshow"
  );
  if (!showAll) departures = departures.filter((x) => x.status !== "checkout");

  return departures;
}
export function inHouseReserves(reserves) {
  return checkByDate(reserves, new Date()).filter(
    (x) => x.status === "inhouse"
  );
}

export function vacantRooms(rooms, reserves) {
  const activeReserves = checkByDate(reserves, new Date());
  const ooo = rooms.filter((x) => x.status === "ooo").length;
  return rooms.length - activeReserves.length - ooo;
}

export function paxCalculate(rooms, reserves, type) {
  let pax = 0;
  switch (type) {
    case "inHouse":
      const inHouse = reserves.filter((x) => x.status === "inhouse");
      inHouse &&
        inHouse.forEach((reserve) => {
          pax += reserve.adult + reserve.children;
        });
      break;
    case "arrival":
      const arrivals = reserves.filter(
        (x) => x.status === "confirmed" && isToday(x.arrival)
      );
      arrivals &&
        arrivals.forEach((reserve) => {
          pax += reserve.adult + reserve.children;
        });
      break;
    case "departure":
      const departures = reserves.filter(
        (x) => x.status === "inhouse" && isToday(x.departure)
      );
      departures &&
        departures.forEach((reserve) => {
          pax += reserve.adult + reserve.children;
        });
      break;
    case "vacant":
      let total = 0;
      let arrival = 0;
      const vacantRooms = rooms.filter(
        (x) => x.status !== "ooo" && x.status !== "occupied"
      );
      vacantRooms &&
        vacantRooms.forEach((room) => {
          total += room.capacity;
        });
      const todayArrivals = reserves.filter(
        (x) => x.status === "confirmed" && isToday(x.arrival)
      );
      todayArrivals &&
        todayArrivals.forEach((reserve) => {
          arrival += reserve.room.capacity;
        });
      pax = total - arrival;
      break;

    default:
      break;
  }

  return pax;
}

const arrivalListMenuItems = [
  { name: "ثبت ورود", icon: "check", value: "checkIn" },
  { name: "عدم حضور", icon: "clock", value: "noShow" },
  { name: "لغو", icon: "remove", value: "cancel" },
  { name: "تسویه", icon: "money", value: "balance" },
  { name: "تغییر اتاق", icon: "refresh", value: "changeRoom" },
];

const departureListMenuItems = [
  { name: "تسویه", icon: "money", value: "balance" },
  { name: "ثبت خروج", icon: "chevronprev", value: "checkOut" },
];

export const arrivalListClass = (item) => {
  let statusStyle = "";
  const balanceColor = item.balance === 0 ? "text-info" : "text-danger";
  switch (item.status) {
    case "inhouse":
      statusStyle = "text-decoration-line-through";
      break;
    case "confirmed":
      statusStyle = "fw-bold";
      break;
    case "noshow":
      statusStyle = "text-muted";
      break;
    case "wait":
      statusStyle = "text-warning";
      break;

    default:
      break;
  }

  return balanceColor + " " + statusStyle;
};
export const departureListClass = (item) => {
  let statusStyle = "";
  const balanceColor = item.balance === 0 ? "text-info" : "text-danger";
  switch (item.status) {
    case "inhouse":
      statusStyle = "fw-bold";
      break;
    case "checkout":
      statusStyle = "text-decoration-line-through";
      break;
    //   case "noshow":
    //     statusStyle = "text-muted";
    //     break;
    //   case "wait":
    //     statusStyle = "text-warning";
    //     break;

    default:
      break;
  }

  return balanceColor + " " + statusStyle;
};
