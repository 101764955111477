import React from "react";
import "./userCard.css";
import Swal from "sweetalert2";
import { deleteUser } from "../../store/users";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { isUserAdmin } from "./../../services/authService";

export default function UserCard({ user, onChangePassword }) {
  const dispatch = useDispatch();
  const handleDelete = (user) => {
    Swal.fire({
      html: `<h2 class=' yekan-bold'>آیا از حذف کاربر اطمینان دارید ${user.userName}   ?</h2>`,
      // text: "You won't be able to revert this!",
      icon: "warning",
      footer:
        "<span class='text-danger yekan'>عملیات غیر قابل برگشت است</span>",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      reverseButtons: true,
      focusCancel: true,
      cancelButtonText: "انصراف",
      confirmButtonText: "حذف",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteUser(user.userName));
      }
    });
  };

  return (
    <div>
      <div className="user card-container co glass">
        {user.roles.some((x) => x === "Admin") && (
          <span className="pro">Admin</span>
        )}
        <img className="round profile" src="user.png" alt="user" />
        <h3 className="mt-2 text-dark">{user.userName}</h3>
        {/* <h6>New York</h6> */}
        <p className=" text-dark">{user.email}</p>
        <div className="user buttons  yekan-med">
          <button
            className="btn btn-warning btn-sm m-1"
            onClick={() => {
              if (!isUserAdmin()) {
                toast.error(
                  "این عملیات فقط با دسترسی کاربر ارشد امکان پذیر است",
                  {
                    theme: "colored",
                  }
                );
                return;
              } else {
                onChangePassword(user.userName);
              }
            }}
          >
            تغییر رمز
          </button>
          <button
            className="btn btn-danger btn-sm m-1"
            onClick={() => {
              handleDelete(user);
            }}
          >
            حذف کاربر
          </button>
        </div>
        <div className="skills">
          <h6>Roles</h6>
          <ul>
            {user.roles.map((x) => (
              <li key={x}>{x}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
