import React, { useState, Fragment } from "react";
import Input from "../common/input";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import _ from "lodash";
import { changePass } from "../../services/authService";
import { CheckBox } from "devextreme-react/check-box";
import { isUserAdmin } from "./../../services/authService";
export default function ChangePassword(props) {
  const [data, setData] = useState({
    userName: props.userName,
    newPassword: "",
    oldPassword: props.fromAdmin ? "dont need" : "",
    // password_confirmation: "",
  });
  const [isAdmin, setAdmin] = useState(false);
  const schema = {
    userName: Joi.string()
      .required()
      .max(50)
      .label("UserName")
      .error(() => {
        return {
          message: "Enter correct username",
        };
      }),
    oldPassword: Joi.string()
      .required()
      .max(50)
      .label("Old Password")
      .error(() => {
        return {
          message: "Enter Old password",
        };
      }),
    newPassword: Joi.string()
      //   .pattern(new RegExp("^[a-zA-Z0-9]{3,30}$"))
      .required()
      .min(6)
      .label("Password"),
    password_confirmation: Joi.any()
      .equal(Joi.ref("newPassword"))
      .required()
      .label("Confirm password")
      .error(() => {
        return {
          message: "Password is not match",
        };
      }),
  };
  const [errors, setErrors] = useState({});

  const validate = () => {
    const result = Joi.validate(data, schema, {
      abortEarly: false,
    });

    if (!result.error) return null;
    let errors = {};
    toast.error("error");
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    console.log(errors);
    setErrors(errors);
    return errors;
  };
  //   const formErrors = validate();

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const inputSchema = { [name]: schema[name] };
    const { error } = Joi.validate(obj, inputSchema);
    if (!error) return null;
    return error.details[0].message;
  };

  const handleChange = (input) => {
    const inputData = { ...data };
    const inputErrors = { ...errors };
    const errorMessage = validateProperty(input);
    if (errorMessage) inputErrors[input.name] = errorMessage;
    else delete inputErrors[input.name];
    inputData[input.name] = input.value;
    setData(inputData);
    setErrors(inputErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors || {});

    if (errors) {
      return;
    }

    try {
      changePass(data, props.fromAdmin);
      props.onClose();
    } catch (ex) {
      toast.error(ex.response.data.message);
      if (ex.response && ex.response.status === 500) {
        // const err = { ...errors };
        // err.password =
        //   "Password must include uppercase,lowercase symbol and numbers";
        // setErrors(err);
      } else {
      }
    }

    // store.dispatch(addCustomer(data));

    // this.doSubmit();
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="card-body">
        {/* <Input
          name="userName"
          label="نام کاربری"
          onChange={handleChange}
          value={data.userName}
          errors={errors["userName"]}
          hidden
        ></Input> */}

        {/* <label>Color</label> */}
        {!props.fromAdmin && (
          <Input
            name="oldPassword"
            label="رمز قدیم"
            onChange={handleChange}
            value={data["oldPassword"]}
            errors={errors["oldPassword"]}
            type="password"
            req={true}
          ></Input>
        )}
        <Input
          name="newPassword"
          label="رمز عبور جدید"
          onChange={handleChange}
          value={data["newPassword"]}
          errors={errors["newPassword"]}
          type="password"
          req={true}
        ></Input>
        <Input
          name="password_confirmation"
          label="تکرار رمز عبور جدید"
          onChange={handleChange}
          value={data["password_confirmation"]}
          errors={errors["password_confirmation"]}
          type="password"
          req={true}
        ></Input>
      </div>
      <div className="d-grid gap-2 bd-highlight ">
        <button type="submit" className="btn btn-primary">
          تغییر رمز
        </button>
        <button
          type="button"
          className="btn btn-light "
          onClick={() => props.onClose()}
          // disabled={Boolean(validate())}
        >
          انصراف
        </button>
      </div>
    </form>
  );
}
