import { createSlice, current } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import axios from "axios";
import { apiCallBegan } from "./api";
import moment from "moment";

const slice = createSlice({
  name: "customers",
  initialState: { list: [], loading: false },

  // lastFetch: null,
  reducers: {
    customersRequested: (customers, action) => {
      customers.loading = true;
    },

    customersReceived: (customers, action) => {
      customers.list = action.payload;
      customers.loading = false;
    },

    customersRequestFailed: (customers, action) => {
      customers.loading = false;
    },

    customerEdited: (customers, action) => {
      const { id } = action.payload;
      const index = customers.list.findIndex((customer) => customer.id === id);
      customers.list[index] = action.payload;
    },

    customerAdded: (customers, action) => {
      customers.list.push(action.payload);
    },

    customerDeleted: (customers, action) => {
      customers.list = customers.list.filter(
        (customer) => customer.id !== action.payload
      );
    },
  },
});

export const {
  customerAdded,
  customerDeleted,
  customerEdited,
  customersReceived,
  customersRequested,
  customersRequestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "/customers";

export const loadCustomers = () => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url,
      onStart: customersRequested.type,
      onSuccess: customersReceived.type,
      onError: customersRequestFailed.type,
    })
  );
};

export const addCustomer = (customer) =>
  apiCallBegan({
    url,
    method: "post",
    data: customer,
    onSuccess: customerAdded.type,
  });

export const deleteCustomer = (id) =>
  apiCallBegan({
    url: url + "/" + id,
    method: "delete",
    data: { id: id },
    onSuccess: customerDeleted.type,
  });

export const editCustomer = (customer) =>
  apiCallBegan({
    url: url + "/" + customer.id,
    method: "put",
    data: customer,
    onSuccess: customerEdited.type,
  });

// Selector

// Memoization
// customers => get unresolved customers from the cache

// export const getCustomersById = (state, customerId) => {
//   return state.entities.customers.list.filter((x) => x.id === customerId);
// };
// createSelector(
//   (state) => state.entities.customers,
//   (customers) => customers.filter((customer) => customer.id === id)

// );

export const getCustomerById = createSelector(
  (state, customerId) =>
    state.entities.customers.list.filter((x) => x.id === customerId),
  (customers) => customers
);
