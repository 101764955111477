import React from "react";

export default function DashboardCard(props) {
  const { title, text, bottom, footer, className } = props;
  return (
    <div className="card  text-center  ">
      <div className="card-body ">
        <h5 className="card-title yekan-bold ">{title}</h5>
        <h1 style={{ fontSize: "5rem" }} className="card-subtitle my-4 ">
          <strong className={className + " yekan-bold"}>{text}</strong>
        </h1>
        <h5 className="card-title yekan-bold text-muted">{bottom}</h5>
      </div>
      {footer && <div className="card-footer yekan-med">{footer}</div>}
    </div>
  );
}
