import { createSlice, current } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import axios from "axios";
import { apiCallBegan } from "./api";
import moment from "moment";

const slice = createSlice({
  name: "users",
  initialState: { list: [], loading: false },

  // lastFetch: null,
  reducers: {
    usersRequested: (users, action) => {
      users.loading = true;
    },

    usersReceived: (users, action) => {
      users.list = action.payload;
      users.loading = false;
    },

    usersRequestFailed: (users, action) => {
      users.loading = false;
    },

    userEdited: (users, action) => {
      const { id } = action.payload;
      const index = users.list.findIndex((user) => user.id === id);
      users.list[index] = action.payload;
    },

    userAdded: (users, action) => {
      users.list.push(action.payload);
    },

    userDeleted: (users, action) => {
      users.list = users.list.filter(
        (user) => user.userName !== action.payload.message
      );
    },
  },
});

export const {
  userAdded,
  userDeleted,
  userEdited,
  usersReceived,
  usersRequested,
  usersRequestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "/Authenticate";

export const loadUsers = () => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + "/getusersandroles",
      method: "get",
      onStart: usersRequested.type,
      onSuccess: usersReceived.type,
      onError: usersRequestFailed.type,
    })
  );
};

export const addUser = (user) => {
  console.log(user);
  apiCallBegan({
    url: url + "/register",
    method: "post",
    data: user,
    onSuccess: userAdded.type,
  });
};

export const deleteUser = (username) =>
  apiCallBegan({
    url: url + "/" + username,
    method: "delete",
    data: { username: username },
    onSuccess: userDeleted.type,
  });

export const editUser = (user) =>
  apiCallBegan({
    url: url + "/" + user.id,
    method: "put",
    data: user,
    onSuccess: userEdited.type,
  });

// Selector

// Memoization
// users => get unresolved users from the cache

// export const getUsersById = (state, userId) => {
//   return state.entities.users.list.filter((x) => x.id === userId);
// };
// createSelector(
//   (state) => state.entities.users,
//   (users) => users.filter((user) => user.id === id)

// );
