import React, { Fragment, useState, useEffect } from "react";
import { DropDownButton } from "devextreme-react/drop-down-button";
import { FaBroom } from "react-icons/fa";
import broom from "../../assets/img/broom.svg";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { editRoom } from "../../store/rooms";
import { toast } from "react-toastify";
import { viewReserve } from "../../utils/reserveFunctions";
import { loadRooms } from "./../../store/rooms";
import { Button } from "devextreme-react/button";
import { Popup, Position, ToolbarItem } from "devextreme-react/popup";
import DateBox from "devextreme-react/date-box";
import mapReserve from "../../utils/mapReserves";
import moment from "moment";
import reserveActions from "../../utils/reservationActions";
import { isToday } from "../../utils/todayEvents";

export default function RoomRackCard({ data: room, reserve: rawReserve }) {
  const dispatch = useDispatch();
  const reserve = mapReserve({ data: rawReserve });
  const [reserveInfo, setReserveInfo] = useState(
    reserve.length > 1
      ? reserve.filter((x) => isToday(x.departure))[0]
      : reserve[0]
  );

  const [colorHintVisible, setColorHintVisible] = useState(false);
  const [colorHint, setColorHint] = useState("");
  const [popupVisible, setPopupVisible] = useState(false);
  const [balanceIsZero, setBalanceZero] = useState(
    rawReserve.length > 0 ? (rawReserve[0].balance === 0 ? false : true) : false
  );

  useEffect(() => {
    setReserveInfo(
      reserve.length > 1
        ? reserve.filter((x) => isToday(x.departure))[0]
        : reserve[0]
    );
    setBalanceZero(
      rawReserve.length > 0
        ? rawReserve[0].balance === 0
          ? false
          : true
        : false
    );
  }, [rawReserve, room]);

  const Statuses = [
    { name: "اشغال", icon: "fa fa-bed text-warning", value: "occupied" },
    { name: "آماده", icon: "fa fa-check text-success", value: "clean" },
    { name: "کثبف", icon: broom, value: "dirty" },
    { name: "خارج از سرویس", icon: "fa fa-ban text-danger", value: "ooo" },
  ];

  const roomClass = {
    occupied: "primary",
    ooo: "secondary",
    dirty: "warning",
    clean: "success",
  };
  const checkOutButtonOption = {
    icon: "return",
    text: "خروج مهمان",
    type: "danger",
    stylingMode: "contained",
    onClick: () => {
      reserveActions.setReserve(rawReserve[0], dispatch, "checkOut");
      setPopupVisible(false);
    },
  };
  const balanceButtonOption = {
    icon: "money",
    text: "تسویه",
    type: "danger",
    stylingMode: "outlined",
    onClick: () => {
      reserveActions.setReserve(rawReserve[0], dispatch, "balance");
    },
  };
  const detailButtonOption = {
    icon: "fa fa-eye",
    text: "",
    hint: "View reserve",
    type: "danger",
    stylingMode: "contained",
    onClick: () => {
      setPopupVisible(false);
      viewReserve(reserveInfo);
    },
  };

  const onRoomStatusSelect = (e) => {
    //to edit room status
    if (e.itemData.value === room.status) {
      toast.info(`${room.name} is already ${e.itemData.name}`, {
        theme: "colored",
      });
      return;
    }

    Swal.fire({
      //   title: "Do you want to save changes",
      text: `آیا وضعیت ${room.name} را به ${e.itemData.name} تغییر میدهید؟`,
      //   footer: `Reserve No.:${e.appointmentData.text} | Guest:${e.appointmentData.customer} `,
      // icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#337ab7",
      cancelButtonColor: "#d9534f",
      reverseButtons: false,
      focusConfirm: true,
      cancelButtonText: "انصراف",
      confirmButtonText: "ثبت تغییر",
    }).then((result) => {
      if (result.isConfirmed) {
        const edited = { ...room };
        edited.status = e.itemData.value;

        dispatch(editRoom(edited));
        toast.success(`${room.name} set to ${e.itemData.name}`, {
          theme: "colored",
        });

        // dispatch(deleteReserve(id));
      } else if (result.isDismissed) {
        return;
        // dispatch(loadRooms());
      }
    });
    // showToast("Updated", e.appointmentData, "info");
  };

  const cardClass = () => {
    let _class = "";
    if (room.status === "clean" && reserve.length > 0) {
      _class = "card-subtitle my-3 text-center text-success";
    } else if (reserve.length === 0 && room.status === "clean") {
      _class = "card-subtitle my-3 text-center text-info";
    } else if (reserve.length > 0 && room.status !== "occupied") {
      _class = "card-subtitle my-3 text-center text-danger";
    } else if (
      reserve[0] &&
      moment(reserve[0].departure)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .isSame(
          moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        ) &&
      reserve.length === 1
    ) {
      _class = "card-subtitle my-3 text-center  bg-warning";
    } else if (reserve.length > 1 && room.status === "occupied") {
      _class = "card-subtitle my-3 text-center text-white bg-danger";
    } else {
      _class = `card-subtitle my-3 text-center text-${roomClass[room.status]}`;
    }
    return _class;
  };

  return (
    <Fragment>
      <div className="card">
        <div className="card-body">
          {/* <h5 className="card-title">Special title treatment</h5> */}
          <DropDownButton
            // splitButton={true}
            useSelectMode={false}
            text={
              room.status && Statuses.find((x) => x.value === room.status).name
            }
            icon={
              room.status && Statuses.find((x) => x.value === room.status).icon
            }
            items={Statuses}
            displayExpr="name"
            keyExpr="id"
            width="100%"
            //   onButtonClick={console.log(
            //     Statuses.find((x) => x.value === data.status).value
            //   )}
            onItemClick={(e) => onRoomStatusSelect(e)}
          />
          <h1 style={{ fontSize: "2rem" }} className={cardClass()}>
            {room.name}
          </h1>
          <small className="text-muted">{room.number}</small>
          {/* <p className="card-text">
          With supporting text below as a natural lead-in to additional content.
        </p> */}
          {/* <button className="btn btn-primary">Go somewhere</button> */}
        </div>
        <div className=" d-flex">
          {reserve.length === 0 && (
            <Button
              className="m-2"
              width="100%"
              text="رزرو نشده"
              type="default"
              disabled={true}
              stylingMode="outlined"
              //   onClick={this.onClick}
            />
          )}
          {reserve.length > 0 && (
            <Button
              className="m-2"
              width="100%"
              text="اطلاعات رزرو"
              type="success"
              stylingMode="contained"
              onClick={() => {
                setPopupVisible(true);
              }}
            />
          )}
        </div>
        {/* <div className="card-footer">
        <h5> Featured</h5>
    </div> */}
      </div>

      <Popup
        visible={popupVisible}
        onHiding={() => setPopupVisible(false)}
        // on={setPopupVisible(false)}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={true}
        showTitle={true}
        title="اطلاعات رزرو"
        container="#roomRack"
        width={300}
        height={380}
      >
        {reserve.length > 0 ? (
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="before"
            disabled={!balanceIsZero}
            options={balanceButtonOption}
          />
        ) : null}
        {reserve.length > 0 ? (
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            disabled={balanceIsZero}
            options={checkOutButtonOption}
          />
        ) : null}
        {/* <ToolbarItem
          widget="dxButton"
          toolbar="top"
          location="after"
          options={detailButtonOption}
        /> */}
        <Position at="center" my="center" of="#roomRack" />
        {reserveInfo && (
          <Fragment>
            <p>
              شماره رزرو:&nbsp;
              <span>{reserveInfo.reserveNumber}</span>&nbsp;
            </p>
            <p>
              نام مهمان :&nbsp;
              <span>{reserveInfo.customer}</span>&nbsp;
            </p>
            <p>
              ورود:&nbsp;
              <span
                className={isToday(reserveInfo.arrival) ? "text-success" : ""}
              >
                {reserveInfo.arrival &&
                  new Date(reserveInfo.arrival).toLocaleDateString("fa")}
              </span>
              &nbsp;
            </p>
            <p>
              خروج:&nbsp;
              <span
                className={isToday(reserveInfo.departure) ? "text-danger" : ""}
              >
                {reserveInfo.departure &&
                  new Date(reserveInfo.departure).toLocaleDateString("fa")}
              </span>
              &nbsp;
            </p>
            <p className="mt-1">
              مانده حساب:&nbsp;
              <span>{reserveInfo.balance}</span>&nbsp;
            </p>
            <Button
              className=""
              type="normal"
              width="100%"
              icon="fa fa-eye"
              // text="New Guest"
              stylingMode="contained"
              hint="View reserve"
              onClick={() => {
                setPopupVisible(false);
                viewReserve(reserveInfo);
              }}
            />
          </Fragment>
        )}
      </Popup>
    </Fragment>
  );
}
