import Query from "devextreme/data/query";

export default function mapReserve({ data }) {
  if (Array.isArray(data)) {
    const formattedData = [];
    data.forEach((element) => {
      const {
        id,
        reserveNumber,
        arrival,
        adult,
        children,
        departure,
        status,
        room,
        customer,
        reserver,
        paid,
        price,
        discount,
        createdOn,
        balance,
      } = element;
      const formattedElement = {
        id,
        reserveNumber,
        arrival,
        departure,
        adult,

        children,
        status,
        room: room.name,
        roomId: room.id,
        customer: customer.firstName + " " + customer.lastName,
        customerId: customer.id,
        reserver,
        paid,
        price,
        discount,
        createdOn,
        balance,
      };

      formattedData.push(formattedElement);
    });
    return formattedData;
  } else {
    const {
      id,
      reserveNumber,
      arrival,
      adult,
      children,
      departure,
      status,
      room,
      customer,
      reserver,
      paid,
      price,
      discount,
      createdOn,
      balance,
    } = data;
    const formattedElement = {
      id,
      reserveNumber,
      arrival,
      departure,
      adult,
      children,
      status,
      room: room.name,
      roomId: room.id,
      customer: customer.firstName + " " + customer.lastName,
      reserver,
      customerId: customer.id,
      paid,
      price,
      discount,
      createdOn,
      balance,
    };
    return formattedElement;
  }
}

export function mapReserveForCalendar({ data }) {
  const formattedData = [];

  data.forEach((element) => {
    const { departure, room, customer, ...rest } = element;
    const endDate = new Date(departure);
    endDate.setDate(endDate.getDate() - 1);

    const formattedElement = {
      text: rest.reserveNumber,
      departure: endDate.toISOString(),
      roomId: room.id,
      room: room.name,
      customer: customer.firstName + " " + customer.lastName,
      customerId: customer.id,
      ...rest,
    };

    formattedData.push(formattedElement);
  });
  return formattedData;
}

export function mapReserveBack({ data }) {
  const formattedData = [];

  data.forEach((element) => {
    const {
      reserveNumber,
      room,
      customer,
      ...rest
      // id,
      // arrival,
      // adult,
      // children,
      // departure,
      // status,
      // paid,
      // price,
      // discount,
      // createdOn,
      // balance,
    } = element;
    const formattedElement = {
      text: reserveNumber,
      roomId: room.id,
      room: room.name,
      customer: customer.firstName + " " + customer.lastName,
      customerId: customer.id,
      ...rest,
      // id,
      // arrival,
      // departure,
      // adult,
      // children,
      // status,
      // paid,
      // price,
      // discount,
      // createdOn,
      // balance,
    };

    formattedData.push(formattedElement);
  });
  return formattedData;
}
// const colors = [{ "": "red" }];

// console.log(MyEnum["3fa85f64-5717-4562-b3fc-2c963f66afa6"]);
export function mapRoomForRack({ data }) {
  const formattedData = [];

  data.forEach((element) => {
    const { id, name, color, capacity, roomId } = element;
    const formattedElement = {
      text: name,
      id,
      color: color,
    };

    formattedData.push(formattedElement);
  });
  return formattedData;
}

export function appointmentTemplate(model) {
  // const movieInfo = getMovieById(model.appointmentData.movieId) || {};
  // console.log(model);
  return (
    <div className="">
      <div>
        <p className="yekan fs-85">{model.appointmentData.customer}</p>
      </div>
      <div>
        <p>{model.appointmentData.text}</p>
        <strong>{model.appointmentData.status}</strong>
      </div>
      {/* <p>
        {model.appointmentData.startDate}"-"{model.appointmentData.endDate}
      </p> */}
    </div>
  );
}
