import React from "react";
import { Button } from "devextreme-react/button";

export default function RefreshButton(props) {
  return (
    <Button
      className="mx-2  mb-4"
      type="success"
      stylingMode="outlined"
      hint="Click to refresh"
      icon="refresh"
      onClick={() => {
        props.onClick();
      }}
    ></Button>
  );
}
