import React, { useState, useEffect, useLayoutEffect, Fragment } from "react";
import Input from "../common/input";
import Joi from "joi-browser";
import SelectInput from "../common/SelectInput";
import { makeList } from "../../utils/listMaker4Select";
import { getAvailableRooms } from "../../utils/reserveFunctions";
import { CheckBox } from "devextreme-react/check-box";

const schema = {
  paid: Joi.number().min(0).label("Prepaid"),
  price: Joi.number()
    .required()
    .min(1)
    .label("Price")
    .error(() => {
      return {
        message: "مبلغ اتاق نمیتواند 0 باشد",
      };
    }),
  discount: Joi.number().min(0).label("Discount"),
  balance: Joi.number().min(0).label("Balance"),
  roomId: Joi.string()
    .required()
    .label("Room")
    .error(() => {
      return {
        message: "نام اتاق را وارد کنید",
      };
    }),
  status: Joi.optional(),
  room: Joi.optional(),
};

export default function Step2(props) {
  const [roomSelectLabel, setRoomSelectLabel] = useState(["name"]);

  let propData = { ...props.data };
  delete propData["id"];
  delete propData["isDeleted"];
  delete propData["createdOn"];
  delete propData["customerId"];
  delete propData["customer"];
  delete propData["description"];
  delete propData["reserver"];
  delete propData["arrival"];
  delete propData["color"];
  delete propData["reserveNumber"];
  delete propData["departure"];
  delete propData["adult"];
  //   delete propData["status"];
  delete propData["children"];

  const [data, setData] = useState(propData);
  const [errors, setErrors] = useState({});
  const [showAllRooms, setShowAllRooms] = useState(false);

  const availableRooms = getAvailableRooms(
    props.reserves,
    props.rooms,
    props.data.arrival,
    props.data.departure
  );
  // console.log(makeList(availableRooms, ["name"], "id"));
  // setOptions(makeList(availableRooms, ["name"], "id"));

  const roomOptions = makeList(availableRooms, ["name"], "id", "#006F62");
  const allRoomOptions = makeList(props.rooms, ["name"], "id", "#006F62");

  const handleChange = (input) => {
    const inputData = { ...data };
    const inputErrors = { ...errors };
    const errorMessage = props.onValidateProperty(schema, input);

    if (errorMessage) inputErrors[input.name] = errorMessage;
    else delete inputErrors[input.name];
    inputData[input.name] = input.value;

    if (
      input.name === "price" ||
      input.name === "paid" ||
      input.name === "discount"
    ) {
      const balance =
        inputData["price"] - inputData["paid"] - inputData["discount"];
      inputData["balance"] = balance;
      balance === 0
        ? (inputData["status"] = "confirmed")
        : (inputData["status"] = "");
    }
    setData(inputData);
    setErrors(inputErrors);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = props.onValidate(data, schema);
    setErrors(errors || {});
    if (errors) {
      return;
    }
    const newData = { ...data };
    newData.room = props.rooms.filter((x) => x.id === data.roomId)[0];

    props.onNext(newData);
  };

  return (
    <div dir="rtl">
      <form onSubmit={handleSubmit}>
        <div className="card-body">
          <div className="row align-items-center">
            <div className=" col-md-5 alert-danger rounded">
              <CheckBox
                value={showAllRooms}
                onValueChanged={(e) => {
                  setShowAllRooms(e.value);
                }}
                rtlEnabled={true}
                text="نمایش اتاق های بدون ظرفیت"
              />
            </div>
            <div className="col-md-7">
              <SelectInput
                req={true}
                data={showAllRooms ? allRoomOptions : roomOptions}
                value={data.roomId}
                name={"roomId"}
                label={"اتاق"}
                isColored={true}
                onChange={handleChange}
                errors={errors["roomId"]}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <Input
                min={0}
                name="price"
                label="قیمت"
                type="number"
                req={true}
                onChange={handleChange}
                value={data["price"]}
                errors={errors["price"]}
              ></Input>
            </div>
            <div className="col-md-6">
              <Input
                min={0}
                name="paid"
                label="پیش پرداخت"
                type="number"
                onChange={handleChange}
                value={data["paid"]}
                errors={errors["paid"]}
              ></Input>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Input
                min={0}
                name="discount"
                label="تخفیف"
                type="number"
                onChange={handleChange}
                value={data["discount"]}
                errors={errors["discount"]}
              ></Input>
            </div>
            <div className="col-md-6">
              <Input
                min={0}
                name="balance"
                label="مانده حساب"
                type="number"
                disabled={true}
                onChange={handleChange}
                value={data["balance"]}
                errors={errors["balance"]}
              ></Input>
            </div>
          </div>
          <div className="d-grid gap-2 bd-highlight ">
            <button type="submit" className="btn btn-primary">
              مرحله بعد
            </button>
            <button
              type="button"
              className="btn btn-outline-primary "
              onClick={() => props.setStep(1)}
              // disabled={validate()}
            >
              مرحله قبل
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
