import React, { Fragment } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import InfoButton from "./infoButton";
import chroma from "chroma-js";

export default function SelectInput(props) {
  const {
    // isSearchable = false,
    // defaultValue,
    // isClearable = false,
    isCreatable,
    // isDisabled = false,
    // isLoading,
    // isRtl,
    name,
    data,
    tooltip,
    onChange,
    label,
    errors,
    req,
    value,
    isColored,
    ...rest
  } = props;

  const selected = value ? data.findIndex((d) => d.value === value) : null;

  return (
    <div className="form-group mb-3">
      <label htmlFor={name}>
        {label}{" "}
        {req && (
          <i
            style={{ fontSize: ".7rem" }}
            className="text-danger fa fa-asterisk"
          ></i>
        )}
      </label>
      {!isCreatable ? (
        <Select
          {...rest}
          onChange={(e) => {
            e.name = name;
            onChange(e);
          }}
          isRtl={true}
          placeholder={<div>انتخاب {label} </div>}
          className="w-100"
          id={name}
          required={req}
          options={data}
          styles={isColored ? colourStyles : ""}
          defaultValue={selected ? data[selected] : null}
        />
      ) : (
        <CreatableSelect
          {...rest}
          onChange={(e) => {
            e.name = name;
            onChange(e);
          }}
          // isClearable
          placeholder={<div>انتخاب {label} </div>}
          className="w-100"
          id={name}
          options={data}
          defaultValue={selected ? data[selected] : null}
        />
      )}

      {tooltip && <InfoButton tooltip={tooltip || null}></InfoButton>}

      {errors && <div className="text-danger ">{errors}</div>}
    </div>
  );
}

const dot = (color = "transparent") => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginLeft: 8,
    height: 10,
    width: 10,
  },
});

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : undefined,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? chroma.contrast(color, "white") > 2
          ? "white"
          : "black"
        : data.color,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : color.alpha(0.3).css()
          : undefined,
      },
    };
  },
  input: (styles) => ({ ...styles, ...dot() }),
  placeholder: (styles) => ({ ...styles, ...dot("#ccc") }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};
