import React, { useState, useEffect, Fragment } from "react";
import Input from "./common/input";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import _ from "lodash";
import configureStore from "../store/configureStore";
import { addRoom, editRoom } from "../store/rooms";
import { useSelector, useDispatch } from "react-redux";
import withTooltip from "./hoc/withTooltip";

export default function AddRoom(props) {
  // const store = configureStore();

  // const customers = useSelector((state) => state.entities.customers.list);
  const dispatch = useDispatch();

  const [data, setData] = useState(props.data);
  const schema = {
    id: Joi.string().allow("").optional(),
    isDeleted: Joi.optional(),
    createdOn: Joi.optional(),
    color: Joi.optional(),
    name: Joi.string()
      .required()
      .max(30)
      .label(" نام اتاق")
      .error(() => {
        return {
          message: "نام اتاق را وارد کنید",
        };
      }),
    enName: Joi.string()
      .required()
      .max(30)
      .label("Room Name (English)")
      .error(() => {
        return {
          message: "Enter room name in english",
        };
      }),
    description: Joi.string()
      .allow("")
      .optional()
      .max(255)
      .label(" توضیحات")
      .error(() => {}),
    enDescription: Joi.string()
      .allow("")
      .optional()
      .max(255)
      .label(" Description")
      .error(() => {}),
    number: Joi.string()
      .max(10)
      .required()
      .label("شماره اتاق")
      .error(() => {
        return {
          message: "شماره اتاق را به طور صحیح وارد کنید",
        };
      }),
    capacity: Joi.number()
      .min(1)
      .max(15)
      .allow("")
      .optional()
      .label("ظرفیت")
      .error(() => {
        return {
          message: "ظرفیت اتاق صحیح نمیباشد",
        };
      }),
    reserves: Joi.array().items(Joi.string().allow("").optional()),
  };
  const [errors, setErrors] = useState({});

  const validate = () => {
    const result = Joi.validate(data, schema, {
      abortEarly: false,
    });

    if (!result.error) return null;
    let errors = {};
    toast.error("error");
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    setErrors(errors);
    return errors;
  };
  //   const formErrors = validate();

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const inputSchema = { [name]: schema[name] };
    const { error } = Joi.validate(obj, inputSchema);
    if (!error) return null;
    return error.details[0].message;
  };

  const handleChange = (input) => {
    const inputData = { ...data };
    const inputErrors = { ...errors };
    const errorMessage = validateProperty(input);
    if (errorMessage) inputErrors[input.name] = errorMessage;
    else delete inputErrors[input.name];
    inputData[input.name] = input.value;
    setData(inputData);
    setErrors(inputErrors);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors || {});

    if (errors) {
      return;
    }
    if (data === props.data) props.onClose();
    data.id ? dispatch(editRoom(data)) : dispatch(addRoom(data));
    props.onClose();
    // store.dispatch(addCustomer(data));

    // this.doSubmit();
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="card-body">
        <Input
          name="enName"
          label="Room Name (English)"
          onChange={handleChange}
          value={data["enName"]}
          errors={errors["enName"]}
          req={true}
          // tooltip={{
          //   text: "This field is required",
          //   typeAndClass: "warning text-dark",
          // }}
        ></Input>
        <Input
          name="name"
          label="نام اتاق"
          onChange={handleChange}
          value={data["name"]}
          errors={errors["name"]}
          req={true}
          // tooltip={{
          //   text: "This field is required",
          //   typeAndClass: "warning text-dark",
          // }}
        ></Input>

        <Input
          name="color"
          label="رنگ اتاق"
          onChange={handleChange}
          value={data["color"]}
          errors={errors["color"]}
          type="color"
        ></Input>

        <Input
          name="number"
          label="شماره اتاق"
          onChange={handleChange}
          value={data["number"]}
          errors={errors["number"]}
          req={true}
          //   tooltip={{
          //     text: "This field is required",
          //     typeAndClass: "warning text-dark",
          //   }}
        ></Input>
        <Input
          name="capacity"
          label="ظرفیت"
          onChange={handleChange}
          value={data["capacity"]}
          errors={errors["capacity"]}
          //   tooltip={{
          //     text: "This field is required",
          //     typeAndClass: "warning text-dark",
          //   }}
        ></Input>
        <Input
          name="enDescription"
          label="Description (English)"
          onChange={handleChange}
          value={data["enDescription"]}
          errors={errors["enDescription"]}
        ></Input>
        <Input
          name="description"
          label="توضیحات"
          onChange={handleChange}
          value={data["description"]}
          errors={errors["description"]}
        ></Input>
      </div>
      <div className="d-grid gap-2 bd-highlight ">
        <button type="submit" className="btn btn-primary">
          ذخیره
        </button>
        <button
          type="button"
          className="btn btn-light "
          onClick={() => props.onClose()}
          // disabled={Boolean(validate())}
        >
          لغو
        </button>
      </div>
    </form>
  );
}
