import React, { Component } from "react";
import CardBody from "./cardBody";
import CardFooter from "./cardFooter";
import CardImage from "./cardImage";
// import hr from "../../assets/img/HR.jpg";
import CardHeader from "./cardHeader";
import CardPart from "./cardPart";

export default class Card extends Component {
  handleEdit = () => {
    console.log("handleEdit");
  };
  handleDelete = () => {
    console.log("handleDelete");
  };
  render() {
    const {
      number,
      id,
      capacity,
      color,
      description,
      name,
      enName,
      enDescription,
    } = this.props.item;
    return (
      <div className="col">
        <div className="card">
          {/* <CardHeader /> */}
          <CardImage src={require("../../../assets/img/HR.jpg")} />
          <CardPart
            classes="card-body  border-bottom mb-2"
            title={[name, enName]}
            text={[description, enDescription]}
            number={number}
            color={color}
          />
          <CardPart
            classes="card-footer text-center  "
            item={this.props.item}
            button={this.props.button}
          />

          {/* <CardFooter
            content={this.props.content}
            item={this.props.item}
            button={this.props.button}
          /> */}
        </div>
      </div>
    );
  }
}
