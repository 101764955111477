import React from "react";
import withTooltip from "../hoc/withTooltip";

function InfoButton({ tooltip, showTooltip }) {
  return (
    <div>
      <span
        className={`ms-2 rounded-pill badge bg-${
          tooltip.typeAndClass || "info  text-dark"
        }  `}
      >
        {tooltip.character || "!"}
      </span>
      {showTooltip && (
        <span
          className={`badge text-wrap info-badge  bg-${
            tooltip.typeAndClass || "info  text-dark"
          }  w-25`}
        >
          {tooltip.text}
        </span>
      )}
    </div>
  );
}
export default withTooltip(InfoButton);
