import React, { useState, useEffect, Fragment } from "react";

import { loadRooms } from "./store/rooms";
import { loadCustomers } from "./store/customers";
import { loadReserves, getActiveReserves } from "./store/reserves";
import { ToastContainer } from "react-toastify";
import { useLocation, Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import "react-dates/initialize";
import jwt_Decode from "jwt-decode";
import routes, { getRoutes } from "./routes.js";
import Sidebar from "./components/Sidebar";
import AdminNavbar from "./components/AdminNavbar";
import Modal from "./components/common/Modal";
import sidebarImage from "../src/assets/img/sidebar-3.jpg";
import Register from "./components/Auth/Register";
import Login from "./components/Auth/Login";
import Temp from "./components/temp";
import {
  autoLogout,
  getCurrentUser,
  getJwt,
  isAdmin,
} from "./services/authService";
import Swal from "sweetalert2";
import NotFound from "./Pages/404";
import Dashboard from "./components/Dashboard";
import DashboardNew from "./components/DashboardNew";
import { useSelector } from "react-redux";
import Loader from "./components/Loader";
import { Button } from "devextreme-react/button";
import "devextreme/dist/css/dx.light.css";
import "devextreme/dist/css/dx.common.css";
import "./App.css";

function App() {
  const dispatch = useDispatch();
  const mainPanel = React.useRef(null);
  const location = useLocation();
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);
  useEffect(() => {
    if (getJwt()) {
      dispatch(loadReserves());
      dispatch(loadRooms());
      dispatch(loadCustomers());
      setUser(getCurrentUser());
    }
    autoLogout();
  }, []);

  const [user, setUser] = useState(null);
  //toggle modal
  const roomIsLoading = useSelector((state) => state.entities.rooms.loading);
  const reserveIsLoading = useSelector(
    (state) => state.entities.reserves.loading
  );
  const customerIsLoading = useSelector(
    (state) => state.entities.customers.loading
  );
  const [registerModal, showRegisterModal] = useState({
    isOpen: false,
    title: "Register",
  });
  const [loginModal, showLoginModal] = useState({
    isOpen: false,
    title: "Login",
  });

  const resetModal = () => {
    showRegisterModal({
      isOpen: false,
      title: "Register",
    });
    showLoginModal({
      isOpen: false,
      title: "Register",
    });
  };
  // DevExpress.config({
  //   rtlEnabled: true,
  // });

  return (
    <div className="wrapper ">
      <ToastContainer />
      {roomIsLoading && reserveIsLoading && customerIsLoading && !getJwt() && (
        <Loader></Loader>
      )}

      <Sidebar
        color="black"
        image={true ? sidebarImage : ""}
        routes={routes}
      ></Sidebar>
      <div className="main-panel rtl" ref={mainPanel}>
        <AdminNavbar
          user={user}
          onRegister={
            () =>
              Swal.fire({
                html: "<h3 class='yekan-med'>برای افزودن کاربر به بخش کاربران مراجعه کنید</h3>",
                footer:
                  "<p class='yekan text-info'>این قسمت فقط برای کاربر ارشد قابل دسترس است</p>",
                icon: "info",
                confirmButtonText: "تایید",
              })
            // showRegisterModal({
            //   isOpen: true,
            //   title: "Register New User",
            // })
          }
          onLogin={() =>
            showLoginModal({
              isOpen: true,
              title: "ورود",
            })
          }
        />
        <div className="content">
          {(getJwt() && (
            <Routes>
              {getRoutes(routes)}
              <Route path="/" exact element={<DashboardNew />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          )) || (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img src="logo.svg" className="text-center" width={"40%"}></img>
              <h1 className="yekan-bold text-center p-4 alert-info">
                برای کار با نرم افزار ابتدا وارد حساب کاربری خود شوید
              </h1>

              <Button
                width={120}
                icon="nc-icon nc-circle-09"
                text="ورود"
                type="success"
                stylingMode="contained"
                onClick={() =>
                  showLoginModal({
                    isOpen: true,
                    title: "ورود ",
                  })
                }
              />
            </div>
          )}
        </div>

        {/* <Footer /> */}
      </div>
      {registerModal.isOpen === true ? (
        <Fragment>
          <Modal
            title={registerModal.title}
            content={
              <Register
                onClose={() => {
                  resetModal();
                }}
              ></Register>
            }
            onClose={() => {
              resetModal();
            }}
          />
          <div className="modal-backdrop fade show"></div>
        </Fragment>
      ) : null}
      {loginModal.isOpen === true ? (
        <Fragment>
          <Modal
            title={loginModal.title}
            content={
              <Login
                onClose={() => {
                  resetModal();
                }}
              ></Login>
            }
            onClose={() => {
              resetModal();
            }}
          />
          <div className="modal-backdrop fade show"></div>
        </Fragment>
      ) : null}
    </div>
  );
}

export default App;
