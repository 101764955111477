import Dashboard from "./components/Dashboard";
import DashboardNew from "./components/DashboardNew";
import GuestList from "./components/GuestList";
import ReserveList from "./components/ReserveList";
import RoomList from "./components/RoomList";
import { Route } from "react-router-dom";
import Temp from "./components/temp";
import RoomRack from "./components/RoomRack";
import RoomCalendar from "./components/RoomCalendar";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import DevexDashboard from "./components/DevExtreme/DevexDashboard";
import UserManagement from "./components/UserManagement";

const dashboardRoutes = [
  // {
  //   // upgrade: true,
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icon: "nc-icon nc-grid-45",
  //   component: Dashboard,
  //   layout: "",
  // },
  {
    // upgrade: true,
    path: "/Dashboard",
    name: "داشبورد",
    icon: "nc-icon nc-grid-45",
    component: DashboardNew,
    layout: "",
  },
  {
    path: "/rack",
    name: "وضعیت هتل",
    icon: "far fa-chart-bar",
    component: RoomCalendar,
    layout: "",
  },
  {
    path: "/roomrack",
    name: "وضعیت اتاق ها",
    icon: "fa fa-th",
    component: RoomRack,
    layout: "",
  },
  {
    path: "/customers",
    name: "مهمانان",
    icon: "fa fa-users",
    component: GuestList,
    layout: "",
  },
  {
    path: "/rooms",
    name: "اتاق ها",
    icon: "fa fa-bed",
    component: RoomList,
    layout: "",
  },
  {
    path: "/reserves",
    name: "رزروها",
    icon: "far fa-id-card",
    component: ReserveList,
    layout: "",
  },
  {
    path: "/users",
    name: "کاربران",
    icon: "far fa-user",
    isAdmin: true,
    component: UserManagement,
    layout: "",
  },
  {
    path: "/login",
    name: "Login",
    icon: "fa fa-user",
    component: Login,
    layout: "",
    navbar: true,
  },
  {
    path: "/register",
    name: "Register",
    icon: "fa fa-card",
    component: Register,
    layout: "",
    navbar: true,
  },
  // {
  //   path: "/temp",
  //   name: "Temp",
  //   icon: "fa fa-qrcode",
  //   component: Temp,
  //   layout: "",
  //   // isHome: true,
  // },
  // {
  //   path: "/dev",
  //   name: "Devex Dashboard",
  //   icon: "fa fa-qrcode",
  //   component: DevexDashboard,
  //   layout: "",
  //   // isHome: true,
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "nc-icon nc-pin-3",
  //   component: Maps,
  //   layout: "/admin"
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "nc-icon nc-bell-55",
  //   component: Notifications,
  //   layout: "/admin"
  // }
];

export default dashboardRoutes;

export const getRoutes = (routes) => {
  return routes.map((prop, key) => {
    if (!prop.isHome && !prop.navbar) {
      return (
        <Route
          path={prop.layout + prop.path}
          element={<prop.component />}
          key={key}
        />
      );
    } else {
      return;
      <Route exact path="/" element={Dashboard} key={key} />;
    }
  });
};
