/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { useLocation, NavLink, Link } from "react-router-dom";

import { Nav } from "react-bootstrap";

import logo from "../assets/img/reactlogo.png";

function Sidebar({ color, image, routes }) {
  const location = useLocation();
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  return (
    <div className="sidebar rtl" data-image={image} data-color={color}>
      <div
        className="sidebar-background"
        style={{
          backgroundImage: "url(" + image + ")",
        }}
      />
      <div className="sidebar-wrapper">
        <div className="logo d-flex align-items-center justify-content-start yekan-bold">
          <Link to="/" className="simple-text logo-mini mx-1">
            <div className="logo-img">
              <img src={require("../assets/img/maleklogo.png")} alt="..." />
            </div>
          </Link>

          <Link className="simple-text" to="/">
            سرای ملک
          </Link>
        </div>

        <Nav className="yekan-bold">
          {routes
            .filter((x) => !x.isHome && !x.navbar)
            .map((prop, key) => {
              if (!prop.redirect)
                return (
                  <li
                    className={
                      prop.upgrade
                        ? "active active-pro"
                        : activeRoute(prop.layout + prop.path)
                    }
                    key={key}
                  >
                    <NavLink
                      to={prop.layout + prop.path}
                      className="nav-link"
                      activeclassname="active"
                    >
                      <i className={prop.icon} />
                      <p>{prop.name}</p>
                    </NavLink>
                  </li>
                );
              return null;
            })}
        </Nav>
        <div className="glass p-4 m-2 yekan-bold">
          <h4>امروز : {new Date().toLocaleDateString("fa")} </h4>
          <h4>Today : {new Date().toDateString()} </h4>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
