// import { star } from "fontawesome";
import React, { useState, useEffect } from "react";
// import { DateRangePicker } from "react-dates";
import moment from "moment";
// import momentJalaali from "moment-jalaali";
import InfoButton from "./infoButton";
// import { getDay } from "./../../utils/dateFunctions";
import "../../assets/css/datepicker.css";
import { DatePicker } from "zaman";

export default function DateRange(props) {
  const {
    startDate: start,
    endDate: end,
    onChange,
    startLabel,
    startName,
    endName,
    errors,
    locale,
    label,
    req,
    endLabel,
    tooltip,
    small = false,
  } = props;
  let options = { month: "long", day: "numeric" };

  // const [focusedInput, setFocusedInput] = useState();
  const [startDate, setStart] = useState(
    (start && moment(new Date(start))) || null
  );
  const [endDate, setEnd] = useState((start && moment(new Date(end))) || null);

  // const from = startDate ? startDate.format("YYYY-MM-DDTHH:mm:ss.sssZ") : null;
  // const to = endDate ? endDate.format("YYYY-MM-DDTHH:mm:ss.sssZ") : null;

  useEffect(() => {
    // if (!startDate) {
    //   console.log("invalid");
    //   return;
    // }
    onChange([
      { name: startName, value: momentToDate(startDate) },
      { name: endName, value: momentToDate(endDate) },
    ]);
    // onChange({ name: endName, value: to });
  }, [startDate, endDate]);

  // useEffect(() => {

  //   onChange({ name: endName, value: momentToDate(endDate) });
  // }, [endDate]);

  const momentToDate = (date) => {
    return new Date(date).toISOString();
    if (date._isValid) {
    }
    return null;
    // date && date.format("YYYY-MM-DDTHH:mm:ss.sssZ");
  };
  // onDatesChange({ startDate, endDate }) {
  //   const { stateDateWrapper } = this.props;
  //   this.setState({
  //     startDate: startDate && stateDateWrapper(startDate),
  //     endDate: endDate && stateDateWrapper(endDate),
  //   });
  // }
  // const onDatesChange = ({ startDate, endDate }) => {
  //   console.log(startDate);
  //   console.log(endDate);
  //   setStart(startDate && startDate);
  //   // setEnd(endDate && endDate);

  //   // console.log(endDate);
  //   // endDate: endDate && stateDateWrapper(endDate),
  // };
  return (
    <div className="form-group mb-3" dir="rtl">
      <label htmlFor="DatePicker">
        {label && label}
        {req && (
          <i
            style={{ fontSize: ".7rem" }}
            className="text-danger fa fa-asterisk"
          ></i>
        )}
      </label>
      <div className="input-group">
        <DatePicker
          direction="rtl"
          round="x1"
          id="DatePicker"
          accentColor="#0057B7"
          locale={locale || "fa"}
          inputClass="datePickerInput"
          from={moment(new Date(startDate))}
          to={moment(new Date(endDate))}
          // accentColor="#6374ae"
          inputAttributes={{
            placeholder:
              startDate == endDate
                ? "انتخاب تاریخ رزرو"
                : `${new Date(startDate).toLocaleDateString(
                    locale && locale === "fa" ? "fa-IR" : "en",
                    options
                  )} ${locale && locale === "fa" ? "تا" : "to"} ${new Date(
                    endDate
                  ).toLocaleDateString(
                    locale && locale === "fa" ? "fa-IR" : "en",
                    options
                  )}`,
            // value: `${startDate}-${endDate}`,
          }}
          range
          onChange={(x) => {
            setStart(x.from);
            setEnd(x.to);
          }}
          // defaultValue={`${startDate}-${endDate}`}
        />

        {/* <DateRangePicker
          startDate={
            startDate && moment(new Date(startDate).toLocaleDateString("fa-IR"))
          } // momentPropTypes.momentObj or null,
          startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
          endDate={
            endDate && moment(new Date(endDate).toLocaleDateString("fa-IR"))
          } // momentPropTypes.momentObj or null,
          endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
          small={small}
          showClearDates={true}
          isRTL={true}
          firstDayOfWeek={6}
          anchorDirection="right"
          renderMonthText={(month) =>
            new Intl.DateTimeFormat("fa-IR", {
              month: "short",
            }).format(month)
          }
          // renderWeekHeaderElement={(day) => (
          //   <strong style={{ color: "#FE01E5" }}>
          //     <small>{day.toUpperCase()}</small>
          //   </strong>
          // )}
          renderWeekHeaderElement={(day) => <strong>{getDay(day)}</strong>}
          renderDayContents={(day) => momentJalaali(day).format("jD")}
          showDefaultInputIcon
          stateDateWrapper={momentJalaali}
          startDatePlaceholderText="تاریخ ورود"
          endDatePlaceholderText="تاریخ خروج"
          // renderMonthElement={}
          // startDatePlaceholderText={startLabel}
          // endDatePlaceholderText={endLabel}
          focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onDatesChange={onDatesChange} // PropTypes.func.isRequired,
          onFocusChange={(focusedInput) => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
        /> */}
        {tooltip && <InfoButton tooltip={tooltip || null}></InfoButton>}
        {errors && <div className="text-danger ">{errors}</div>}
      </div>
    </div>
  );
}
