import React, { Fragment } from "react";
import { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  mapReserveForCalendar,
  appointmentTemplate,
} from "../utils/mapReserves";
import { mapRoomForRack } from "../utils/mapReserves";
import Scheduler, { Resource, Scrolling } from "devextreme-react/scheduler";
import { Button } from "devextreme-react/button";
import notify from "devextreme/ui/notify";
import Swal from "sweetalert2";
import { loadReserves } from "../store/reserves";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { LoadPanel } from "devextreme-react/load-panel";

import { deleteReserve, editReserve, getReserveById } from "../store/reserves";

import AddGuest from "./AddGuest";
import AddReserve from "./AddReserve";
import Modal from "./common/Modal";
import customTooltip from "./rack/tooltip";
import { loadRooms } from "../store/rooms";
import LoadingPanel from "./common/LoadingPanel";
import { toast } from "react-toastify";
import RefreshButton from "./RefreshButton";
import NewReserve from "./newReserve";
import DateCell from "./Scheduler/DateCell";
import axios from "axios";
import Test from "./Test";
import ResourceCell from "./Scheduler/ResourceCell";
import DataCell from "./Scheduler/DataCell";

export default function RoomCalendar() {
  const dispatch = useDispatch();

  const rawReserves = useSelector((state) => state.entities.reserves.list);
  const isLoading = useSelector((state) => state.entities.reserves.loading);
  const rawRooms = useSelector((state) => state.entities.rooms.list);
  const reserves = mapReserveForCalendar({ data: rawReserves });
  const roomData = mapRoomForRack({ data: rawRooms });
  // const currentState = useSelector((state) => state);

  const renderDateCell = (itemData) => <DateCell itemData={itemData} />;
  const renderDataCell = (itemData) => (
    <DataCell itemData={itemData} rooms={rawRooms} />
  );
  const renderResourceCell = (itemData) => <ResourceCell itemData={itemData} />;

  const rawGuestForm = {
    id: "",
    firstName: "",
    lastName: "",
    phone: "",
    idNumber: "",
    job: "",
    birthday: "",
    reserves: [],
  };
  const rawReserveForm = {
    id: "",
    reserveNumber: "",
    arrival: "",
    departure: "",
    adult: 0,
    children: 0,
    paid: 0,
    price: 0,
    discount: 0,
    balance: 0,
    description: "",
    roomId: "",
    customerId: "",
    status: "",
    color: "000",
  };
  //default data when open modal to add or edit item
  const [guestData, setGuestData] = useState(rawGuestForm);
  const [reserveData, setReserveData] = useState(rawReserveForm);

  const [refreshButton, setRefreshButton] = useState({
    loading: false,
    text: "refresh",
    originalText: "refresh",
  });
  const guestContent = (
    <AddGuest
      data={guestData}
      onClose={() => {
        resetGuestModal();
      }}
    ></AddGuest>
  );
  const reserveContent = (
    <NewReserve
      data={reserveData}
      onAddGuest={() =>
        showGuestModal({
          isOpen: !guestModal.IsOpen,
          title: "ثبت مهمان",
          content: guestContent,
        })
      }
      onClose={() => {
        resetReserveModal();
      }}
    ></NewReserve>
  );
  //toggle modal
  const [reserveModal, showReserveModal] = useState({
    isOpen: false,
    title: "New Reserve",
    content: <p></p>,
  });
  const [guestModal, showGuestModal] = useState({
    isOpen: false,
    title: "New Guest",
    content: <p></p>,
  });
  const [currentDate, setCurrentDate] = useState(new Date(2022, 7, 15));
  const views = [
    // "timelineDay",
    // "timelineWeek",
    // "timelineWorkWeek",
    "timelineMonth",
    // "week",
    "month",
  ];
  const groups = ["roomId"];
  const handlePropertyChange = useCallback((e) => {
    if (e.name === "currentDate") {
      setCurrentDate(e.value);
    }
  }, []);

  const showToast = (event, value, type) => {
    notify(`${event} "${value}" task`, type, 800);
  };

  const showAddedToast = (e) => {
    showToast("Added", e.appointmentData.text, "success");
  };

  const showUpdatedToast = (e) => {
    console.log(e.appointmentData);
    showToast("Updated", e, "info");
  };
  //generate edit message html to use in swal
  const editInfo = (edited, departure) => {
    const reserve = rawReserves.filter((x) => x.id === edited.id)[0];
    const room = rawRooms.filter((x) => x.id === edited.roomId)[0];
    return `<ul class="list-group list-group-flush">
      <li class="list-group-item">ورود: <strong>${new Date(
        reserve.arrival
      ).toLocaleDateString("fa")}</strong> <i>به:</i> <strong>${new Date(
      edited.arrival
    ).toLocaleDateString("fa")}</strong> </li>
      <li class="list-group-item">خروج: <strong>${new Date(
        reserve.departure
      ).toLocaleDateString("fa")}</strong> <i>به:</i> <strong>${new Date(
      departure
    ).toLocaleDateString("fa")} </strong></li>
      <li class="list-group-item">اتاق: <strong>${
        reserve.room.name
      }</strong> <i>به:</i> <strong>${room.name} </strong></li>
    </ul>`;
  };
  //to edit reserve directly from scheduler
  const handleDirectEdit = (e) => {
    let departure = new Date(e.appointmentData.departure);
    departure.setDate(departure.getDate() + parseInt(1));
    Swal.fire({
      title: "<h2 class='yekan-bold'>آیا تغییرات زیر اعمال شود؟</h2>",
      html: editInfo(e.appointmentData, departure),
      footer: `شماره رزور:${e.appointmentData.text} | مهمان:${e.appointmentData.customer} `,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#337ab7",
      cancelButtonColor: "#d9534f",
      reverseButtons: false,
      focusConfirm: false,
      cancelButtonText: "انصراف",
      confirmButtonText: "ثبت تغییرات",
    }).then((result) => {
      if (result.isConfirmed) {
        const reserve = rawReserves.filter(
          (x) => x.id === e.appointmentData.id
        )[0];
        const edited = { ...reserve };
        edited.arrival = e.appointmentData.arrival;
        edited.departure = departure.toISOString();
        edited.roomId = e.appointmentData.roomId;

        dispatch(editReserve(edited));
        // toast.info("Reserve edited successfully", {
        //   theme: "colored",
        // });

        // dispatch(deleteReserve(id));
      } else if (result.isDismissed) {
        dispatch(loadReserves());
      }
    });
    // showToast("Updated", e.appointmentData, "info");
  };
  const handleEdit = (item) => {
    const reserve = rawReserves.filter((x) => x.id === item.id)[0];
    setReserveData(reserve);
    showReserveModal({
      isOpen: true,
      title: "Edit Reserve",
      content: (
        <NewReserve
          data={reserve}
          onClose={() => {
            resetReserveModal();
          }}
        ></NewReserve>
      ),
    });
  };

  const handleDelete = ({ text: reserveNumber, id }) => {
    Swal.fire({
      text: `Are you sure want to delete ${reserveNumber} ? `,
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d9534f",
      cancelButtonColor: "#337ab7",
      reverseButtons: false,
      focusCancel: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteReserve(id));
      }
    });
  };
  const resetReserveModal = () => {
    showReserveModal(false);
    // setGuestData(rawGuestForm);
    setReserveData(rawReserveForm);
  };
  const resetGuestModal = () => {
    showGuestModal(false);
    setGuestData(rawGuestForm);
    // setReserveData(rawReserveForm);
  };

  const onAppointmentAdding = (e) => {
    console.log(e);
    // const isValidAppointment = Utils.isValidAppointment(e.component, e.appointmentData);
    // if (!isValidAppointment) {
    //   e.cancel = true;
    //   notifyDisableDate();
    // }
  };
  // const temp = new FormData();
  // temp.append("reserveNumber", "4500");
  <LoadIndicator id="small-indicator" indicatorSrc="images/Loading.gif" />;
  return (
    <div>
      <span className="yekan-bold">
        <Button
          // width={120}
          className="ms-2 mb-4"
          text="رزرو جدید"
          type="success"
          icon="plus"
          stylingMode="contained"
          onClick={() =>
            showReserveModal({
              isOpen: !reserveModal.IsOpen,
              title: "ثبت رزرو",
              content: reserveContent,
            })
          }
        ></Button>
        <Button
          // width={120}
          className="ms-2 mb-4"
          text="مهمان جدید"
          type="default"
          icon="fa fa-user-plus"
          stylingMode="contained"
          onClick={() =>
            showGuestModal({
              isOpen: !guestModal.IsOpen,
              title: "ثبت مهمان",
              content: guestContent,
            })
          }
        />

        <RefreshButton
          onClick={() => {
            dispatch(loadReserves());
            dispatch(loadRooms());
          }}
        />
      </span>
      <Scheduler
        id="scheduler"
        // dataSource={reserves}
        dataSource={reserves}
        // height={580}
        cellDuration={60}
        groups={groups}
        firstDayOfWeek={0}
        startDayHour={8}
        endDayHour={20}
        views={views}
        rtlEnabled={true}
        dateCellRender={renderDateCell}
        dataCellComponent={renderDataCell}
        // defaultCurrentView="timelineMonth"
        defaultCurrentView="timelineMonth"
        defaultCurrentDate={new Date()}
        onOptionChanged={handlePropertyChange}
        onContentReady={(e) => {
          e.component.scrollTo(new Date());
        }}
        appointmentRender={appointmentTemplate}
        appointmentTooltipComponent={(e) =>
          customTooltip(e, handleEdit, handleDelete)
        }
        resourceCellComponent={renderResourceCell}
        textExpr="title"
        startDateExpr="arrival"
        endDateExpr="departure"
        allDayExpr="true"
        allDayPanelMode="allDay"
        recurrenceRuleExpr="recurrence"
        onAppointmentAdded={onAppointmentAdding}
        showCurrentTimeIndicator={false}
        onAppointmentUpdated={handleDirectEdit}
        // crossScrollingEnabled={true}

        onAppointmentFormOpening={(e) => {
          if (!e.appointmentData.id) {
            let departure = new Date(e.appointmentData.departure);
            departure.setDate(departure.getDate() + parseInt(1));
            const newReserve = { ...rawReserveForm };

            newReserve.arrival =
              e.appointmentData.arrival.split("T")[0] + "T08:00:00";
            newReserve.departure =
              e.appointmentData.departure.split("T")[0] + "T08:00:00";
            newReserve.roomId = e.appointmentData.roomId;
            newReserve.reserveNumber = new Date().getTime().toString();
            // newReserve.departure = departure;
            console.log(e.appointmentData);
            console.log(newReserve);

            showReserveModal({
              isOpen: true,
              title: "افزودن رزرو",
              content: (
                <NewReserve
                  data={newReserve}
                  onClose={() => {
                    resetReserveModal();
                  }}
                ></NewReserve>
              ),
            });
          }
          e.cancel = true;
        }}
        // e.popup.option('showTitle', true);
        onAppointmentClick={(e) => {
          // console.log(e.targetedAppointmentData);
        }}
      >
        <Resource
          fieldExpr="roomId"
          allowMultiple={false}
          dataSource={roomData}
          label="Room"
        />
        {/* <Scrolling mode="virtual" /> */}
      </Scheduler>

      <LoadingPanel panel="#scheduler" isLoading={isLoading}></LoadingPanel>
      {reserveModal.isOpen === true ? (
        <Fragment>
          <Modal
            title={reserveModal.title}
            content={reserveModal.content}
            onClose={() => {
              resetReserveModal();
            }}
          />
          <div className="modal-backdrop fade show"></div>
        </Fragment>
      ) : null}
      {guestModal.isOpen === true ? (
        <Fragment>
          <Modal
            //class to show user dialog on reserve dialog
            myClass="second-modal-dialog "
            title={guestModal.title}
            content={guestModal.content}
            onClose={() => {
              resetGuestModal();
            }}
          />
          <div className="modal-backdrop second-modal-back fade show"></div>
        </Fragment>
      ) : null}
    </div>
  );
}
