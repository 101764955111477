import React, { useState, useEffect, useLayoutEffect, Fragment } from "react";
import DropDownBox from "devextreme-react/drop-down-box";
import TreeView from "devextreme-react/tree-view";
import DataGrid, {
  Selection,
  Paging,
  FilterRow,
  Scrolling,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";

export default function DropBoxGrid(props) {
  const gridColumns = ["firstName", "lastName", "idNumber"];
  const gridDataSource = props.data;
  const [gridBoxValue, setGridBoxValue] = useState([props.selected]);
  const [isGridBoxOpened, setGridBoxOpened] = useState(false);

  //   makeAsyncDataSource(jsonFile) {
  //     return new CustomStore({
  //       loadMode: 'raw',
  //       key: 'ID',
  //       load() {
  //         return fetch(`data/${jsonFile}`)
  //           .then((response) => response.json());
  //       },
  //     });
  //   }

  const gridBoxDisplayExpr = (item) => {
    return item && `${item.firstName} ${item.lastName} <${item.idNumber}>`;
  };
  // const gridDataSource = this.makeAsyncDataSource('customers.json');

  const syncDataGridSelection = (e) => {
    setGridBoxValue(e.value);
  };

  const onGridBoxOpened = (e) => {
    if (e.name === "opened") {
      setGridBoxOpened(e.value);
    }
  };
  const dataGridOnSelectionChanged = (e) => {
    props.onChange(e.selectedRowKeys[0]);
    setGridBoxValue(e.selectedRowKeys);
    setGridBoxOpened(false);
  };

  const dataGridRender = () => {
    return (
      <DataGrid
        dataSource={gridDataSource}
        columns={gridColumns}
        hoverStateEnabled={true}
        selectedRowKeys={gridBoxValue}
        onSelectionChanged={dataGridOnSelectionChanged}
        height="100%"
      >
        <Selection mode="single" />
        <Scrolling mode="virtual" />
        <Paging enabled={true} pageSize={10} />
        <FilterRow visible={true} />
      </DataGrid>
    );
  };

  return (
    <div className="mb-3">
      <label>
        {props.label}
        {props.req && (
          <i
            style={{ fontSize: ".7rem" }}
            className="text-danger fa fa-asterisk"
          ></i>
        )}
      </label>
      <DropDownBox
        value={gridBoxValue}
        opened={isGridBoxOpened}
        // valueExpr="idNumber"
        deferRendering={false}
        displayExpr={gridBoxDisplayExpr}
        placeholder="Select a guest..."
        showClearButton={false}
        dataSource={gridDataSource}
        onValueChanged={syncDataGridSelection}
        onOptionChanged={onGridBoxOpened}
        contentRender={dataGridRender}
      />
      {props.errors && <div className="text-danger ">{props.errors}</div>}
    </div>
  );
}
