import React, { Fragment, useState } from "react";
import RoomRackCard from "./card/roomRackCard";
import { useSelector, useDispatch } from "react-redux";
import RefreshButton from "./RefreshButton";
import { checkByDate, checkTodayReserves } from "./../utils/checkRoomReserve";
import { Popup, Position, ToolbarItem } from "devextreme-react/popup";
import { loadReserves } from "../store/reserves";
import { loadRooms } from "../store/rooms";

export default function RoomRack(props) {
  const dispatch = useDispatch();
  const reserves = useSelector((state) => state.entities.reserves.list);
  const isLoading = useSelector((state) => state.entities.rooms.loading);
  const rooms = useSelector((state) => state.entities.rooms.list);
  const activeReserves = checkTodayReserves(reserves);

  const [colorHintVisible, setColorHintVisible] = useState(false);
  const [colorHint, setColorHint] = useState("");

  const colorInfoButton = {
    icon: "fa fa-check",
    text: "OK",
    type: "normal",
    stylingMode: "outlined",
    onClick: () => setColorHintVisible(false),
  };

  return (
    <div id="roomRack" className="yekan">
      <div>
        <RefreshButton
          onClick={() => {
            dispatch(loadReserves());
            dispatch(loadRooms());
          }}
        />

        <span className="align-top ">
          <strong>راهنمای رنگ ها:</strong>
          <input
            className="btn btn-outline-success ms-2  mb-2"
            type="button"
            defaultValue="رزرو شده و آماده"
            onClick={() => {
              setColorHint(
                "Room is ready to accept guests and has reserve today"
              );
              setColorHintVisible(true);
            }}
          />
          <input
            className="btn btn-outline-secondary ms-2  mb-2"
            type="button"
            defaultValue="خارج از سرویس"
            onClick={() => {
              setColorHint("Room is Out Of Order");
              setColorHintVisible(true);
            }}
          />
          <input
            className="btn btn-outline-info ms-2  mb-2"
            type="button"
            defaultValue="آماده"
            onClick={() => {
              setColorHint("Room is ready to accept guests");
              setColorHintVisible(true);
            }}
          />
          <input
            className="btn btn-outline-primary ms-2  mb-2"
            type="button"
            defaultValue="اشغال"
            onClick={() => {
              setColorHint("Guest already occupy in room");
              setColorHintVisible(true);
            }}
          />
          <input
            type="button"
            className="btn btn-outline-warning ms-2  mb-2"
            defaultValue="کثیف"
            onClick={() => {
              setColorHint(
                "Room is not ready to accept guests and need to get clean"
              );
              setColorHintVisible(true);
            }}
          />
          <input
            type="button"
            className="btn btn-outline-danger ms-2  mb-2"
            defaultValue="رزرو شده و کثیف"
            onClick={() => {
              setColorHint(
                "Room is not ready to accept guests but has reserve today"
              );
              setColorHintVisible(true);
            }}
          />
          <input
            type="button"
            className="btn btn-warning ms-2  mb-2"
            defaultValue="خروج امروز"
            onClick={() => {
              setColorHint("Guests check-out today");
              setColorHintVisible(true);
            }}
          />
          <input
            type="button"
            className="btn btn-danger ms-2  mb-2"
            defaultValue="رزرو شده و پر"
            onClick={() => {
              setColorHint(
                "Guests check-out but there is another reserve for today"
              );
              setColorHintVisible(true);
            }}
          />
        </span>
      </div>
      <div className="row">
        {rooms.map((room) => (
          <div key={room.id} className="col-md-3">
            <RoomRackCard
              reserve={activeReserves.filter(
                (x) => x.room.id === room.id && x.status !== "checkout"
              )}
              data={room}
            ></RoomRackCard>
          </div>
        ))}
      </div>
      <Popup
        visible={colorHintVisible}
        onHiding={() => setColorHintVisible(false)}
        // on={setPopupVisible(false)}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={false}
        showTitle={false}
        // title="Text Color Guid"
        container="#roomRack"
        width={300}
        height={150}
      >
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          // disabled={!balanceIsZero}
          options={colorInfoButton}
        />
        <Position at="center" my="center" of="#roomRack" />
        <span>{colorHint}</span>
      </Popup>
    </div>
  );
}
