import moment from "moment";
import React from "react";

let options = { month: "long", day: "numeric" };

export default function DateCell({ itemData, type }) {
  return (
    <div
      className="yekan d-flex"
      title={new Date(itemData.date).toDateString()}
    >
      <small className={isWeekEnd(itemData.date) ? "text-danger" : ""}>
        {moment(itemData.date).format("dddd")}
      </small>
      {!type && (
        <div
          className="yekan d-flex"
          title={new Date(itemData.date).toDateString()}
        >
          <small className={isWeekEnd(itemData.date) ? "text-danger" : ""}>
            {new Date(itemData.date).toLocaleDateString("fa-IR", options)}
          </small>
          {/* <br></br>
          <small>{new Date(itemData.date).toDateString().split(" ")[1]}</small>
          <small>{new Date(itemData.date).toDateString().split(" ")[2]}</small> */}
        </div>
      )}
    </div>
  );
}

export function MonthDataCell({ data }) {
  const date = new Date(data.arrival);
  const jalali = date.toLocaleDateString("fa").split("/")[2];

  return (
    <div>
      <div className={isWeekEnd(date) ? "text-danger" : ""}>{jalali}</div>
    </div>
  );
}

function isWeekEnd(date) {
  const day = date.getDay();
  return day === 5;
}
