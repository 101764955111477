import React, { useState, useEffect, useLayoutEffect, Fragment } from "react";
import Input from "../common/input";
import Joi from "joi-browser";
import SelectInput from "../common/SelectInput";
import RadioButtonGroup from "../common/radioButtonGroup";
import Textarea from "../common/textArea";
import { makeList } from "./../../utils/listMaker4Select";

const schema = {
  status: Joi.optional(),
  reserver: Joi.string().allow(null, "").optional().max(50),
  description: Joi.string().allow(null, "").optional().max(250),
};

export default function Step3(props) {
  let propData = { ...props.data };
  delete propData["reserveNumber"];
  delete propData["id"];
  //   delete propData["description"];
  //   delete propData["status"];
  delete propData["color"];
  delete propData["customer"];
  delete propData["isDeleted"];
  delete propData["createdOn"];
  delete propData["arrival"];
  delete propData["departure"];
  delete propData["customerId"];
  delete propData["room"];
  delete propData["balance"];
  delete propData["paid"];
  delete propData["price"];
  delete propData["discount"];
  delete propData["adult"];
  delete propData["children"];
  delete propData["roomId"];

  const [data, setData] = useState(propData);
  const [errors, setErrors] = useState({});
  const [reserverSelectLabel, setReserverSelectLabel] = useState(["reserver"]);
  const reserverOptions = makeList(
    props.reserves,
    reserverSelectLabel,
    "reserver"
  );
  console.log(reserverOptions);
  const handleChange = (input) => {
    const inputData = { ...data };
    const inputErrors = { ...errors };
    const errorMessage = props.onValidateProperty(schema, input);
    if (errorMessage) inputErrors[input.name] = errorMessage;
    else delete inputErrors[input.name];
    inputData[input.name] = input.value;

    setData(inputData);
    setErrors(inputErrors);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = props.onValidate(data, schema);
    setErrors(errors || {});
    if (errors) {
      return;
    }
    props.onNext(data);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="card-body" dir="rtl">
          <div className="row">
            <div className="form-group">
              <SelectInput
                data={reserverOptions}
                value={data.reserver}
                isCreatable={true}
                name={"reserver"}
                label={"رزرو کننده"}
                onChange={handleChange}
                errors={errors["reserver"]}
              />

              <label>وضعیت رزرو</label>
              <RadioButtonGroup
                name="status"
                onChange={handleChange}
                selected={data.status}
                options={[
                  { style: "success", label: "تایید", value: "confirmed" },
                  { style: "danger", label: "لغو شده", value: "canceled" },
                  { style: "secondary", label: "عدم حضور", value: "noshow" },
                  {
                    style: "warning",
                    label: "در انتظار پرداخت",
                    value: "wait",
                  },
                ]}
              ></RadioButtonGroup>
            </div>
          </div>

          <div className="col-md-12 mt-3">
            <Textarea
              name="description"
              label="توضیحات"
              onChange={handleChange}
              value={data["description"]}
              errors={errors["description"]}
              //   rows="4"
              rows="3"
            ></Textarea>
          </div>
        </div>
        <div className="d-grid gap-2 bd-highlight ">
          <button type="submit" className="btn btn-primary">
            مرحله بعد
          </button>
          <button
            type="button"
            className="btn btn-outline-primary "
            onClick={() => props.setStep(2)}

            // disabled={validate()}
          >
            مرحله قبل
          </button>
        </div>
      </form>
    </div>
  );
}
