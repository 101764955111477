import React, { useState, useEffect, useLayoutEffect, Fragment } from "react";
import Input from "../common/input";
import Joi from "joi-browser";
import SelectInput from "../common/SelectInput";
import RadioButtonGroup from "../common/radioButtonGroup";
import Textarea from "../common/textArea";
import GetVoucher from "./../../utils/getVoucher";

const schema = {
  reserveNumber: Joi.string().required().label("Reserve No."),
  id: Joi.optional(),
  description: Joi.string().allow(null, "").optional().max(250),
  status: Joi.optional(),
  color: Joi.optional(),
  room: Joi.optional(),
  customer: Joi.optional(),
  isDeleted: Joi.optional(),
  reserver: Joi.string().allow(null, "").optional().max(50),
  createdOn: Joi.optional(),
  arrival: Joi.string().isoDate().label("Check-In and Check-Out"),
  departure: Joi.string().isoDate(),
  paid: Joi.number().min(0).label("Prepaid"),
  price: Joi.number().min(1).required().label("Price"),
  discount: Joi.number().min(0).label("Discount"),
  balance: Joi.number().min(0).label("Balance"),
  adult: Joi.number().optional().min(1).max(6).label("Adults"),
  children: Joi.number().optional().min(0).max(6).label("Children"),
  roomId: Joi.string().required().label("Room"),
  customerId: Joi.string().required().label("Guest"),
};

export default function Step4(props) {
  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = props.onValidate(data, schema);
    setErrors(errors || {});
    if (errors) {
      return;
    }
    props.onNext(data);
  };
  const [data, setData] = useState(props.data);
  const [errors, setErrors] = useState({});

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="card-body">
          {/* <div className="form-group"> */}
          {/* <label>Reserve Status</label> */}

          <div className="d-grid gap-2 bd-highlight ">
            <button
              type="button"
              onClick={() => props.onSaveAndSubmit()}
              className="btn btn-success"
            >
              ذخیره رزرو و دریافت تاییدیه
            </button>
            <button type="submit" className="btn btn-primary">
              ذخیره رزرو
            </button>
            <button
              type="button"
              className="btn btn-outline-primary "
              onClick={() => props.setStep(3)}

              // disabled={validate()}
            >
              مرحله قبل
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
