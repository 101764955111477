import React, { useEffect, useState } from "react";
// import Query from "devextreme/data/query";
// import "devextreme/localization/globalize/date";
// import { useDispatch } from "react-redux";
// import {
//   mapRoomForRack,
//   mapReserveForCalendar,
// } from "./../../utils/mapReserves";
// import { useSelector } from "react-redux";
import { viewReserve } from "../../utils/reserveFunctions";
import { Button } from "devextreme-react/button";

// function getMovieById(id) {
//   return reserves.filter((x) => x.id === id);
// }

export default function customTooltip(data, onEdit, onDelete) {
  const { appointmentData: reserve } = data.data;
  return (
    <div className="row bg-light yekan-med">
      <div className={`col-10 text-left`}>
        <ul className="list-group list-group-flush px-0">
          <li className="text-end list-group-item">
            <span>شماره رزرو: </span>
            <strong> {reserve.text}</strong>
          </li>
          <li className="text-end list-group-item">
            <span>مهمان: </span>
            <strong>({reserve.customer})</strong>
          </li>
          <li className="text-end list-group-item">
            <span>اتاق: </span>
            <strong>{reserve.room}</strong>
          </li>
          <li className="text-end list-group-item">
            <span>وضعیت رزرو: </span>
            <strong>{reserve.status}</strong>
          </li>
          <li className="text-end list-group-item">
            <span>مانده حساب: </span>
            <strong>{reserve.balance}</strong>
          </li>
        </ul>
      </div>
      <div className="col-2 d-flex flex-column bd-highlight align-self-center ">
        {onDelete && (
          <Button
            // width={120}
            className="align-self-center mb-2"
            //   text="New Reserve"
            type="danger"
            icon="trash"
            stylingMode="contained"
            onClick={() => onDelete(reserve)}
          />
        )}
        {onEdit && (
          <Button
            // width={120}

            className="align-self-center  mb-2"
            //   text="New Reserve"
            type="success"
            icon="edit"
            stylingMode="contained"
            onClick={() => onEdit(reserve)}
          />
        )}
        <Button
          className=""
          type="normal"
          // width="100%"
          icon="fa fa-eye"
          // text="New Guest"
          stylingMode="contained"
          hint="View reserve"
          onClick={() => {
            // setPopupVisible(false);
            viewReserve(reserve);
          }}
        />
      </div>
    </div>

    /* <img src={movieData.image} /> */
  );
}

// export default class AppointmentTooltip extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       reserveData: getMovieById(props.data.appointmentData.movieId),
//     };
//   }

//   render() {
//     // const { reserveData } = this.state;
//     return (

//     );
//   }
// }
