import { createSlice, current } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import axios from "axios";
import { apiCallBegan } from "./api";
import moment from "moment";

const slice = createSlice({
  name: "rooms",
  initialState: { list: [], loading: false },

  // lastFetch: null,
  reducers: {
    roomsRequested: (rooms, action) => {
      rooms.loading = true;
    },

    roomsReceived: (rooms, action) => {
      rooms.list = action.payload;
      rooms.loading = false;
    },

    roomsRequestFailed: (rooms, action) => {
      rooms.loading = false;
    },

    roomEdited: (rooms, action) => {
      const { id } = action.payload;
      const index = rooms.list.findIndex((room) => room.id === id);
      rooms.list[index] = action.payload;
    },

    roomAdded: (rooms, action) => {
      rooms.list.push(action.payload);
    },

    roomDeleted: (rooms, action) => {
      rooms.list = rooms.list.filter((room) => room.id !== action.payload);
    },
  },
});

export const {
  roomAdded,
  roomDeleted,
  roomEdited,
  roomsReceived,
  roomsRequested,
  roomsRequestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "/rooms";

export const loadRooms = () => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url,
      onStart: roomsRequested.type,
      onSuccess: roomsReceived.type,
      onError: roomsRequestFailed.type,
    })
  );
};

export const addRoom = (room) =>
  apiCallBegan({
    url,
    method: "post",
    data: room,
    onSuccess: roomAdded.type,
  });

export const deleteRoom = (id) =>
  apiCallBegan({
    url: url + "/" + id,
    method: "delete",
    data: { id: id },
    onSuccess: roomDeleted.type,
  });

export const editRoom = (room) =>
  apiCallBegan({
    url: url + "/" + room.id,
    method: "put",
    data: room,
    onSuccess: roomEdited.type,
  });

// Selector

// Memoization
// rooms => get unresolved rooms from the cache

// export const getRoomsById = (state, roomId) => {
//   return state.entities.rooms.list.filter((x) => x.id === roomId);
// };
// createSelector(
//   (state) => state.entities.rooms,
//   (rooms) => rooms.filter((room) => room.id === id)

// );

export const getRoomById = createSelector(
  (state, roomId) => state.entities.rooms.list.filter((x) => x.id === roomId),
  (rooms) => rooms
);
