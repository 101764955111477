import React, { Component } from "react";

export default class TableHeader extends Component {
  raiseSort = (path) => {
    const sortColumn = { ...this.props.sortColumn };
    if (this.props.sortColumn.path === path) {
      sortColumn.order = sortColumn.order === "asc" ? "desc" : "asc";
    } else {
      sortColumn.path = path;
      sortColumn.order = "asc";
    }

    this.props.onSort(sortColumn);
  };
  render() {
    const { sortColumn, columns } = this.props;
    const order =
      sortColumn.order === "asc" ? "fa fa-sort-up" : "fa fa-sort-down";

    return (
      <thead>
        <tr className="">
          {columns.map((t) => (
            <th
              className="clickable table-dark yekan-bold "
              scope="col"
              key={t.id}
              onClick={() => this.raiseSort(t.path)}
            >
              {sortColumn.path.includes(t.path) ? (
                <div className="">
                  <span className="">{t.title}</span>
                  <i className={order}> </i>
                </div>
              ) : (
                t.title
              )}
            </th>
          ))}
        </tr>
      </thead>
    );
  }
}
