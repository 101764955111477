import persianRex from "persian-rex";
import { apiUrl } from "../config.js";
import axios from "axios";

export default function getVoucher(reserveNumber, customer) {
  // reserveFormData.delete("reserveNumber");
  // reserveFormData.append("reserveNumber", reserveNumber);
  const isPersian = persianRex.text.test(customer);
  const reportUrl = isPersian
    ? `${apiUrl}/Report/persianReport`
    : `${apiUrl}/Report/englishReport`;
  axios({
    url: reportUrl,
    method: "POST",
    responseType: "arraybuffer", // Important! Set the response type to 'blob' to handle binary data
    headers: { reserveNumber: reserveNumber },
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${reserveNumber}-${customer}.pdf`); // Set the desired filename here
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      // Handle any errors
      console.error("Error downloading PDF:", error);
    });

  // axios.post(reportUrl, reserveFormData);
}
