import React, { useState, useEffect, useLayoutEffect, Fragment } from "react";
import Input from "../common/input";
import Joi from "joi-browser";
import DateRange from "../common/dateRangePicker";
import SelectInput from "../common/SelectInput";
import RadioButtonGroup from "../common/radioButtonGroup";
import { makeList } from "../../utils/listMaker4Select";
import DropBoxGrid from "./../DropBoxGrid";
import RadioGroup from "devextreme-react/radio-group";

const schema = {
  reserveNumber: Joi.string().required().label("Reserve No."),
  arrival: Joi.string().isoDate().label("Check-In and Check-Out"),
  departure: Joi.string().isoDate(),
  adult: Joi.number().optional().min(1).max(6).label("Adults"),
  children: Joi.number().optional().min(0).max(6).label("Children"),
  customer: Joi.optional(),
  customerId: Joi.string().required().label("Guest"),
};

export default function Step1(props) {
  let propData = { ...props.data };
  delete propData["id"];
  delete propData["color"];
  delete propData["isDeleted"];
  delete propData["createdOn"];
  delete propData["balance"];
  delete propData["description"];
  delete propData["reserver"];
  delete propData["discount"];
  delete propData["paid"];
  delete propData["price"];
  delete propData["roomId"];
  delete propData["room"];
  delete propData["status"];

  const [data, setData] = useState(propData);
  const [errors, setErrors] = useState({});
  const [locale, setLocal] = useState("fa");
  const [reserveNo, setReserveNo] = useState(new Date().getTime().toString());

  const [customerSelectLabel, setCustomerSelectLabel] = useState([
    "firstName",
    "lastName",
  ]);

  useEffect(() => {
    const number = new Date().getTime().toString();
    // handleChange({reserveNo:})
    setReserveNo(number);
    handleChange({ name: "reserveNumber", value: number });
    if (data.arrival) {
      handleChange({ name: "arrival", value: data.arrival });
      handleChange({ name: "departure", value: data.departure });
    }
  }, []);

  const customerOptions = makeList(props.customers, customerSelectLabel, "id");

  const handleDateChange = (input) => {
    const inputData = { ...data };
    input.forEach((element) => {
      inputData[element.name] = element.value;

      // handleChange(element);
    });
    setData(inputData);
  };

  const handleChange = (input) => {
    const inputData = { ...data };
    const inputErrors = { ...errors };
    const errorMessage = props.onValidateProperty(schema, input);
    if (errorMessage) inputErrors[input.name] = errorMessage;
    else delete inputErrors[input.name];
    inputData[input.name] = input.value;

    setData(inputData);
    setErrors(inputErrors);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = props.onValidate(data, schema);
    setErrors(errors || {});
    if (errors) {
      return;
    }
    const newData = { ...data };
    newData.customer = props.customers.filter(
      (x) => x.id === data.customerId
    )[0];

    // if (data === props.data) props.onClose();
    props.onNext(newData);
    // store.dispatch(addCustomer(data));

    // this.doSubmit();
  };

  const temp = new FormData();
  temp.append("reserveNumber", "4500");
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <Input
                name="reserveNumber"
                label="شماره رزرو"
                // onChange={handleChange}
                value={data["reserveNumber"] || reserveNo}
                errors={errors["reserveNumber"]}
                readOnly
                req={true}
              ></Input>
            </div>
            <div className="col-md-6 d-flex">
              <button
                type="button"
                className="btn btn-outline-dark m-auto"
                onClick={() => props.onAddGuest()}
              >
                <i className="fa fa-user-plus ms-2"></i> مهمان جدید
              </button>
            </div>
          </div>
          <div className="row">
            <DropBoxGrid
              req={true}
              label="انتخاب مهمان"
              data={props.customers}
              selected={
                props.customers.filter(
                  (x) => x.id === props.data.customerId
                )[0] || null
              }
              errors={errors["customerId"]}
              onChange={(customer) => {
                customer &&
                  handleChange({ name: "customerId", value: customer.id });
              }}
            ></DropBoxGrid>
          </div>

          <div className="row  align-items-center">
            <div className="col-md-3">
              <RadioGroup
                id="radio-group-with-selection"
                items={[
                  { id: "fa", text: "شمسی" },
                  { id: "en", text: "میلادی" },
                ]}
                rtlEnabled
                value={locale}
                valueExpr="id"
                displayExpr="text"
                onValueChanged={(e) => {
                  setLocal(e.value);
                }}
              />
            </div>
            <div className="col-md-9">
              <DateRange
                req={true}
                label="تاریخ رزرو"
                startDate={data["arrival"]}
                endDate={data["departure"]}
                startLabel="Check-In"
                endLabel="Check-Out"
                onChange={handleDateChange}
                locale={locale}
                startName="arrival"
                small={true}
                endName="departure"
                errors={errors["arrival"]}
                // tooltip={{
                //   text: "If dates are not empty, To change dates use clear button first",
                //   typeAndClass: "warning text-dark",
                // }}
              ></DateRange>
            </div>

            {/* 
            <button type="button" onClick={() => getRooms()}>
              check
            </button> */}
          </div>
          <div className="row">
            <div className="col-md-6">
              <Input
                req={true}
                min={1}
                name="adult"
                label="بزرگسال"
                type="number"
                onChange={handleChange}
                value={data["adult"]}
                errors={errors["adult"]}
              ></Input>
            </div>
            <div className="col-md-6">
              <Input
                name="children"
                min={0}
                label="کودک"
                type="number"
                onChange={handleChange}
                value={data["children"]}
                errors={errors["children"]}
              ></Input>
            </div>
          </div>
        </div>
        <div className="d-grid gap-2 bd-highlight ">
          <button type="submit" className="btn btn-primary">
            مرحله بعد
          </button>
          <button
            type="button"
            className="btn btn-outline-primary "
            onClick={() => props.onClose()}
            // disabled={validate()}
          >
            انصراف
          </button>
        </div>
      </form>
    </div>
  );
}
