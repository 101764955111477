/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { useLocation } from "react-router-dom";
import { Navbar, Container, Nav, Dropdown, Button } from "react-bootstrap";
import routes from "../routes.js";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { logout, getCurrentUser, getJwt } from "../services/authService.js";
import { useState } from "react";
import ChangePassword from "./Auth/ChangePassword";
import Modal from "./common/Modal";

function Header(props) {
  const location = useLocation();
  const mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  };

  const getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  let navigate = useNavigate();

  const [changePassModal, showChangePassModal] = useState({
    isOpen: false,
    title: "Change password",
    userName: "",
  });

  const resetModal = () => {
    showChangePassModal({
      isOpen: false,
      title: "Change password",
      userName: "",
    });
  };
  return (
    <>
      <Navbar bg="light" expand="lg" className="yekan-bold">
        <Container fluid>
          <div className=" d-flex justify-content-center align-items-center ml-2 ml-lg-0">
            <Button
              variant="dark"
              className="d-lg-none btn-fill d-flex justify-content-center align-items-center rounded-circle p-2"
              onClick={mobileSidebarToggle}
            >
              <i className="fas fa-ellipsis-v"></i>
            </Button>
            <Navbar.Brand
              href="#home"
              onClick={(e) => e.preventDefault()}
              className="mr-2"
            >
              {getBrandText()}
            </Navbar.Brand>
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2 ">
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
          </Navbar.Toggle>
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-between"
          >
            {/* to set date item in center */}
            <div></div>
            <div className=" text-center  p-2  yekan-bold">
              <h4>امروز : {new Date().toLocaleDateString("fa")} </h4>
              <h4>Today : {new Date().toDateString()} </h4>
            </div>
            <Nav className="ml-auto" navbar>
              {/* <Nav.Item>
                <Nav.Link
                  className="m-0"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <span className="no-icon">حساب کاربری</span>
                </Nav.Link>
              </Nav.Item> */}

              {!props.user ? (
                <>
                  <Nav.Item>
                    <Nav.Link
                      className="m-0"
                      // to="/login"
                      onClick={(e) => {
                        e.preventDefault();
                        props.onLogin();
                      }}
                    >
                      <i className="nc-icon nc-circle-09"></i>

                      <span className="no-icon">ورود</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className="m-0"
                      // to="/register"
                      onClick={(e) => {
                        e.preventDefault();
                        props.onRegister();
                      }}
                    >
                      <i className="nc-icon nc-key-25"></i>

                      <span className="no-icon">ثبت نام</span>
                    </Nav.Link>
                  </Nav.Item>
                </>
              ) : (
                <>
                  {getJwt() && (
                    <Nav.Item>
                      <Dropdown as={Nav.Item}>
                        <Dropdown.Toggle
                          aria-expanded={false}
                          aria-haspopup={true}
                          as={Nav.Link}
                          data-toggle="dropdown"
                          id="navbarDropdownMenuLink"
                          variant="default"
                          className="m-0"
                        >
                          <span className="no-icon">
                            <i className="nc-icon nc-single-02"></i>

                            <span className="no-icon">
                              {" "}
                              {getCurrentUser()}{" "}
                            </span>
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu aria-labelledby="navbarDropdownMenuLink">
                          <Dropdown.Item
                            href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              showChangePassModal({
                                isOpen: true,
                                title: "تغییر رمز",
                                userName: getCurrentUser(),
                              });
                            }}
                          >
                            تغییر رمز عبور
                          </Dropdown.Item>
                          {/* <Dropdown.Item
                          className="text-right"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          Another action
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          Something
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          Something else here
                        </Dropdown.Item>
                        <div className="divider"></div>
                        <Dropdown.Item
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          Separated link
                        </Dropdown.Item> */}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Nav.Item>
                  )}
                  <Nav.Item>
                    <Nav.Link
                      className="m-0"
                      // to="/register"
                      onClick={() => {
                        logout();
                        navigate(0);
                      }}
                    >
                      <i className="nc-icon nc-button-power"></i>

                      <span className="no-icon"> خروج </span>
                    </Nav.Link>
                  </Nav.Item>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {changePassModal.isOpen === true ? (
        <>
          <Modal
            title={changePassModal.title}
            content={
              <ChangePassword
                onClose={() => {
                  resetModal();
                }}
                userName={changePassModal.userName}
                fromAdmin={false}
              ></ChangePassword>
            }
            onClose={() => {
              resetModal();
            }}
          />
          <div className="modal-backdrop fade show"></div>
        </>
      ) : null}
    </>
  );
}

export default Header;
