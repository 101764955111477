import React, { useState, useEffect, useLayoutEffect, Fragment } from "react";
import Joi from "joi-browser";
import { addReserve, editReserve, getActiveReserves } from "../store/reserves";
import { makeList } from "./../utils/listMaker4Select";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Step1 from "./reserveWizard/step1";
import Step2 from "./reserveWizard/step2";
import Step3 from "./reserveWizard/step3";
import Stepper from "react-stepper-horizontal";
import DropBoxGrid from "./DropBoxGrid";
import Step4 from "./reserveWizard/step4";
import axios from "axios";
import persianRex from "persian-rex";
import { apiUrl } from "../config.js";
import getVoucher from "./../utils/getVoucher";

export default function NewReserve(props) {
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.entities.customers.list);
  const rooms = useSelector((state) => state.entities.rooms.list);
  const reserves = useSelector((state) => state.entities.reserves.list);
  let myRoom = [];
  let rangeReserves = useSelector(
    (state) => state.entities.reserves.temporaryList
  );
  const getRooms = () => {
    rangeReserves.forEach((reserve) => {
      myRoom.push(reserve.room);
    });
    console.table(myRoom);
  };

  const [roomSelectLabel, setRoomSelectLabel] = useState(["name"]);
  const [customerSelectLabel, setCustomerSelectLabel] = useState([
    "firstName",
    "lastName",
  ]);
  const [data, setData] = useState(props.data);
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (
      data.arrival &&
      data.departure &&
      new Date(data.arrival) < new Date(data.departure)
    ) {
      dispatch(
        getActiveReserves({
          start: new Date(data.arrival).toISOString(),
          end: new Date(data.departure).toISOString(),
        })
      );
    }
    // console.table(rangeReserves);
  }, [data.arrival, data.departure]);
  const schema = {
    reserveNumber: Joi.string().required().label("Reserve No."),
    id: Joi.optional(),
    description: Joi.string().allow(null, "").optional().max(250),
    status: Joi.optional(),
    color: Joi.optional(),
    room: Joi.optional(),
    reserver: Joi.string().allow(null, "").optional().max(50),
    customer: Joi.optional(),
    isDeleted: Joi.optional(),
    createdOn: Joi.optional(),
    arrival: Joi.string().isoDate().label("Check-In and Check-Out"),
    departure: Joi.string().isoDate(),
    paid: Joi.number().min(0).label("Prepaid"),
    price: Joi.number().min(0).label("Price"),
    discount: Joi.number().min(0).label("Discount"),
    balance: Joi.number().min(0).label("Balance"),
    adult: Joi.number().optional().min(1).max(6).label("Adults"),
    children: Joi.number().optional().min(0).max(6).label("Children"),
    roomId: Joi.string().required().label("Room"),
    customerId: Joi.string().required().label("Guest"),
  };
  const roomOptions = makeList(rooms, roomSelectLabel, "id");
  const reserverOptions = makeList(reserves, roomSelectLabel, "id");
  const customerOptions = makeList(customers, customerSelectLabel, "id");

  const reserveFormData = new FormData();

  // const handleDownload = (number) => {
  //   axios({
  //     url: `${apiUrl}/Report/EnglishReport`,
  //     method: "POST",
  //     responseType: "arraybuffer", // Important! Set the response type to 'blob' to handle binary data
  //     headers: { reserveNumber: number },
  //   })
  //     .then((response) => {
  //       console.log(response);
  //       const url = window.URL.createObjectURL(new Blob([response.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", `${number}.pdf`); // Set the desired filename here
  //       document.body.appendChild(link);
  //       link.click();
  //     })
  //     .catch((error) => {
  //       // Handle any errors
  //       console.error("Error downloading PDF:", error);
  //     });
  // };

  const handleValidate = (data, schema) => {
    const result = Joi.validate(data, schema, {
      abortEarly: false,
    });

    if (!result.error) return null;
    let errors = {};
    // toast.error("error");
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    // setErrors(errors);
    return errors;
  };

  const handleValidateProperty = (schema, { name, value }) => {
    const obj = { [name]: value };
    const inputSchema = { [name]: schema[name] };
    const { error } = Joi.validate(obj, inputSchema);
    if (!error) return null;
    return error.details[0].message;
  };

  const putData = (stepData, done = false, needVoucher = false) => {
    const newData = { ...data, ...stepData };
    setData(newData);

    if (done) {
      // GetVoucher(data);
      handleSubmit();
      if (needVoucher) {
        getVoucher(
          newData.reserveNumber,
          data.customer.firstName + " " + data.customer.lastName
        );
      }
    }
  };

  const handleSubmit = () => {
    if (data === props.data) props.onClose();
    data.id ? dispatch(editReserve(data)) : dispatch(addReserve(data));
    props.onClose();
  };

  return (
    <div className="yekan-med">
      <div dir="ltr">
        <Stepper
          steps={[
            {
              title: "اطلاعات اولیه",
              onClick: (e) => {
                e.preventDefault();
                setStep(1);
              },
            },
            {
              title: "اتاق و قیمت",
              onClick: (e) => {
                e.preventDefault();
                setStep(2);
              },
            },
            {
              title: "اطلاعات نهایی",
              onClick: (e) => {
                e.preventDefault();
                setStep(3);
                //   handleSubmit(data);
              },
            },
            {
              title: "تاییدیه",
              onClick: (e) => {
                e.preventDefault();
                setStep(4);
                //   handleSubmit(data);
              },
            },
          ]}
          activeStep={step - 1}
          activeColor="#0057B7"
          barStyle="solid"
        />
      </div>
      {step === 1 && (
        <Step1
          data={data}
          customers={customers}
          onClose={props.onClose}
          onAddGuest={props.onAddGuest}
          onValidate={(data, schema) => handleValidate(data, schema)}
          onValidateProperty={(schema, input) =>
            handleValidateProperty(schema, input)
          }
          onNext={(data) => {
            putData(data);
            setStep(2);
          }}
        ></Step1>
      )}
      {step === 2 && (
        <Step2
          data={data}
          rooms={rooms}
          reserves={reserves}
          onClose={props.onClose}
          onValidate={(data, schema) => handleValidate(data, schema)}
          onValidateProperty={(schema, input) =>
            handleValidateProperty(schema, input)
          }
          setStep={(step) => setStep(step)}
          onNext={(data) => {
            putData(data);
            setStep(3);
          }}
        ></Step2>
      )}
      {step === 3 && (
        <Step3
          data={data}
          reserves={reserves}
          onClose={props.onClose}
          onValidate={(data, schema) => handleValidate(data, schema)}
          onValidateProperty={(schema, input) =>
            handleValidateProperty(schema, input)
          }
          onNext={(data) => {
            putData(data);
            setStep(4);
          }}
          setStep={(step) => setStep(step)}
        ></Step3>
      )}
      {step === 4 && (
        <Step4
          data={data}
          onClose={props.onClose}
          onValidate={(data, schema) => handleValidate(data, schema)}
          onValidateProperty={(schema, input) =>
            handleValidateProperty(schema, input)
          }
          onSaveAndSubmit={(data) => {
            putData(data, true, true);
          }}
          onNext={(data) => {
            putData(data, true);
          }}
          setStep={(step) => setStep(step)}
        ></Step4>
      )}
    </div>
  );
}
