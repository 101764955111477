import React, { useState, Fragment } from "react";
import Input from "../common/input";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import _ from "lodash";
import { addRoom, editRoom } from "../../store/rooms";
import withTooltip from "../hoc/withTooltip";
import { login } from "../../services/authService";
import { useLocation, useNavigate, Navigate } from "react-router-dom";

export default function Login(props) {
  // const store = configureStore();

  // const customers = useSelector((state) => state.entities.customers.list);
  let navigate = useNavigate();

  //   const navigate = useLocation();
  const [data, setData] = useState({
    userName: "",
    password: "",
  });
  const schema = {
    userName: Joi.string()
      .required()
      .max(50)
      .label("UserName")
      .error(() => {
        return {
          message: "نام کاربری را وارد کنید",
        };
      }),
    password: Joi.string()
      .min(6)
      //   .pattern(new RegExp("^[a-zA-Z0-9]{3,30}$"))
      .required()
      .label("Password")
      .error(() => {
        return {
          message: "رمز عبور حداقل 6 حرف می باشد",
        };
      }),

    //   .options({ message: { "any.only": "{{#password}} does not match" } }),
  };
  const [errors, setErrors] = useState({});

  const validate = () => {
    const result = Joi.validate(data, schema, {
      abortEarly: false,
    });

    if (!result.error) return null;
    let errors = {};
    toast.error("error");
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    setErrors(errors);
    return errors;
  };
  //   const formErrors = validate();

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const inputSchema = { [name]: schema[name] };
    const { error } = Joi.validate(obj, inputSchema);
    if (!error) return null;
    return error.details[0].message;
  };

  const handleChange = (input) => {
    const inputData = { ...data };
    const inputErrors = { ...errors };
    const errorMessage = validateProperty(input);
    if (errorMessage) inputErrors[input.name] = errorMessage;
    else delete inputErrors[input.name];
    inputData[input.name] = input.value;
    setData(inputData);
    setErrors(inputErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors || {});

    if (errors) {
      return;
    }
    if (data === props.data) props.onClose();

    try {
      await login(data);
      props.onClose();
      window.location.replace("/dashboard");
      // navigate("/dashboard", { replace: true });
      // navigate(0);
    } catch (ex) {
      if (ex.response && ex.response.status === 401) {
        const err = { ...errors };
        err.userName = "Username or password incorrect";
        setErrors(err);
      } else {
        toast.error("Unexpected error happend");
      }
    }

    // store.dispatch(addCustomer(data));

    // this.doSubmit();
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="card-body">
        <Input
          name="userName"
          label="نام کاربری"
          onChange={handleChange}
          value={data["userName"]}
          errors={errors["userName"]}
          autocomplete
          req={true}
          autoFocus
        ></Input>
        {/* <label>Color</label> */}
        <Input
          name="password"
          label="رمز عبور"
          onChange={handleChange}
          value={data["password"]}
          errors={errors["password"]}
          type="password"
          req={true}
        ></Input>
      </div>
      <div className="d-grid gap-2 bd-highlight ">
        <button type="submit" className="btn btn-primary">
          ورود
        </button>
        <button
          type="button"
          className="btn btn-light "
          onClick={() => props.onClose()}
          // disabled={Boolean(validate())}
        >
          انصراف
        </button>
      </div>
    </form>
  );
}
