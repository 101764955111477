import React from "react";

export default function ReserveCard(props) {
  const {
    reserveNumber,
    arrival,
    departure,
    customer,
    createdOn,
    room,
    adult,
    children,
    price,
    paid,
    balance,
    discount,
    reserver,
  } = props.reserve;
  return (
    <div>
      <div className="text-end alert">
        <div className="alert alert-info yekan">
          <p>
            <strong>نام مهمان :</strong> <span>{customer}</span>
          </p>
          <p>
            <strong>تعداد :</strong>{" "}
            <span>
              {adult} بزرگسال - {children} کودک
            </span>
          </p>
        </div>
        <div className="alert alert-success yekan">
          <p className="">
            <strong> رزرو کننده :</strong> <span>{reserver}</span>
          </p>
          <p className="">
            <strong>شماره رزرو :</strong> <span>{reserveNumber}</span>
          </p>
          <p>
            <strong>تاریخ رزرو :</strong>{" "}
            <span>{new Date(createdOn).toLocaleDateString("fa")}</span>
          </p>
          <p>
            <strong>تاریخ ورود :</strong>{" "}
            <span>{new Date(arrival).toLocaleDateString("fa")}</span>
          </p>
          <p>
            <strong>تاریخ خروج :</strong>{" "}
            <span>{new Date(departure).toLocaleDateString("fa")}</span>
          </p>
          <p>
            <strong>اتاق :</strong> <span>{room}</span>
          </p>
        </div>

        <div className="alert alert-warning yekan">
          <p>
            <strong>قیمت :</strong> <span>{price}</span>
          </p>
          {paid != 0 && (
            <p>
              <strong>پرداختی :</strong> <span>{paid}</span>
            </p>
          )}
          {discount != 0 && (
            <p>
              <strong>تخفیف :</strong> <span>{discount}</span>
            </p>
          )}
          <p>
            <strong>مانده حساب :</strong> <span>{balance}</span>
          </p>
        </div>
      </div>
    </div>
  );
}
