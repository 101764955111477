import React from "react";

export default function Modal(props) {
  const { onClose, title, content, myClass } = props;
  return (
    <div
      dir=""
      className={`${myClass} modal  fade show yekan-bold`}
      id="modal-default"
      style={{ display: "block", paddingRight: "17px" }}
      // style={{"display: block; padding-right: 17px;"}}
      aria-modal="true"
      role="dialog"
    >
      <div
        className="modal-dialog modalBody"
        // ref={(node) => node?.style.setProperty("width", "700px", "important")}
        // style={{ "min-width": { minWidth } }}
      >
        <div className="modal-content">
          <div className="modal-header ">
            <h4 className="modal-title">{title}</h4>
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => onClose()}
            ></button>
          </div>
          <div className="modal-body">{content}</div>
          {/* <div className="modal-footer justify-content-between">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
            >
              Close
            </button>
            <button type="button" className="btn btn-primary">
              Save changes
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
}
